#blog {
  margin-top: 2rem;
  margin-bottom: 3rem;
  .page-title {
    margin-bottom: 2rem;
    h1 {
      padding-bottom: 0;
      .pre {
        display: block;
        font-family: 'WG1', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        margin-bottom: .5rem;
      }
      .tea {
        color: $green-color;
      }
      .coffee {
        color: #9c4d1d;
      }
      .and {
        color: $grey-color;
        font-size: 1.75rem;
      }
    }
  }
  .list {
    .item {
      margin-bottom: 2rem;
      .image {
        margin-bottom: .5rem;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        overflow: hidden;
        a {
          display: block;
          position: relative;
          img {
            max-width: 100%;
          }
        }
      }
        .categories {
            display: block;
            font-family: 'WG1', sans-serif;
            margin-bottom: .5rem;
            ul {
                display: block;
                margin: 0;
                padding: 0;
                color: $grey-color;
                font-size: 0.75rem;
                li {
                    display: inline-block;
                }
            }
        }
      .title {
        margin-bottom: .5rem;
        line-height: 1.3;
        font-size: 1.125rem;
        font-family: $title-font;
      }
      .short_text {
        font-size: 0.875rem;
        line-height: 1.3;
        margin-bottom: 1rem;
        a {
          text-decoration: none;
          color: $black-color;
        }
      }
      .more {
        line-height: 1;
        a {
          color: $green-color;
        }
      }
      &:not(:last-child) {
        border-bottom: 1px dotted $black-color;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
      }
      .params {
        font-size: 0.75rem;
        .date {
          color: $grey-color;
        }
        .views {
          text-align: right;
          color: $grey-color;
        }
      }
    }
  }
  .categories-overlay {
    text-align: center;
    margin-bottom: 2rem;
    .categories {
      display: block;
      border: 1px solid $border-color;
      -webkit-border-radius: 6px;
      -moz-border-radius: 6px;
      border-radius: 6px;
      padding: 1rem 1rem .5rem 1rem;
      text-align: left;
      ul {
        display: block;
        margin: 0;
        padding: 0;
        li {
          display: inline-block;
          &:not(:last-child) {
            margin-right: .5rem;
            margin-bottom: .5rem;
          }
          a {
            display: inline-block;
            padding-bottom: 2px;
            border-bottom: 1px solid $border-color;
            line-height: 1;
            &.active {
              background: $green-color;
              color: #fff;
              padding: 5px 10px;
              border: 0;
              -webkit-border-radius: 6px;
              -moz-border-radius: 6px;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }
}
#blog-detail {
  margin-top: 2rem;
  margin-bottom: 3rem;
  .banner {
    margin-bottom: 2rem;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;
    height: 400px;
  }
  .page-title {
    margin-top: 2rem;
    margin-bottom: 2rem;
    h1 {
      padding-bottom: 0;
    }
  }
  .text {
    h2 {
      font-family: $title-font;
      margin-bottom: 1.5rem;
      line-height: 1.5;
    }
    p {
      line-height: 1.5;
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }
    }
  }
  .products-in-blog {
    margin-bottom: -1rem;
  }
  .bottom {
    font-style: italic;
    margin-top: 1rem;
    .params {
      margin-bottom: 1rem;
      ul {
        margin: 0;
        padding: 0;
        li {
          display: block;
          line-height: 1.5;
        }
      }
    }
    .share {
      .title {
        margin-bottom: .5rem;
      }
    }
  }
}
@media screen and (min-width: 575px) {
  #blog-detail {
    .page-title {
      h1 {
        text-align: left;
      }
    }
    .bottom {
      .share {
        text-align: right;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  #blog {
    .list {
    }
    .categories-overlay {
      .categories {
        display: inline-block;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        ul {
          li {
            &:not(:last-child) {
              margin-right: 1.5rem;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 992px) {
  #blog-detail {
    .text {
      p {
        font-size: 18px;
        font-size: 1.125rem;
      }
    }
    .products-in-blog {
      margin-bottom: -2rem;
    }
  }
}
@media screen and (min-width: 1200px) {
  #blog {
    margin-bottom: 5rem;
    .list {
      .item {
          margin-bottom: 5rem;
        .image {
          margin-bottom: 1rem;
        }
        .title {
          font-size: 1.75rem;
          margin-bottom: 1rem;
        }
        .short_text {
          font-size: 1rem;
          line-height: 1.5;
          margin-bottom: 1rem;
        }
        .more {
          font-size: 1.375rem;
        }
        &:not(:last-child) {
          padding-bottom: 5rem;
          margin-bottom: 5rem;
        }
      }
    }
  }
  #blog-detail {
    margin-bottom: 5rem;
    .page-title {
      padding-left: 120px;
      padding-right: 120px;
      h1 {
        padding-bottom: 0;
      }
    }
    .text {
      p {
        font-size: 22px;
        font-size: 1.375rem;
        padding-left: 120px;
        padding-right: 120px;
      }
      h2 {
        font-size: 30px;
        font-size: 1.875rem;
        padding-left: 120px;
        padding-right: 120px;
      }
    }
    .bottom {
      margin-top: 1.5rem;
      padding-left: 120px;
      padding-right: 120px;
    }
  }
}
