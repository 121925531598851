#cart {
  padding-top: 30px;
  min-height: 500px;
  margin-bottom: 50px;
  h1 {
    font-size: 32px;
    font-size: 2rem;
    font-family: $title-font;
    font-weight: normal;
    padding-bottom: 1.5rem;
    text-align: center;
  }
  .list {
    display: block;
    margin: 0;
    padding: 0;
    li {
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap;
      padding: 30px 0;
      border-bottom: 1px solid $border-color;
      &:last-child {
        border-bottom: none;
      }
    }
    .image {
      img {
        width: 100%;
        height: auto;
      }
    }
    .name {
      font-family: $title-font;
      font-size: 18px;
      font-size: 1.125rem;
      font-weight: 500;
      padding-bottom: 1rem !important;
    }
    .short_name {
      color: #9C531D;
    }
    .quantity-price {
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-align: center;
      align-items: center;
      margin-bottom: 0.5rem;
    }

    .quantity-price .overlay {
      -webkit-box-flex: 0;
      flex: 0 0 150px;
      max-width: 150px;
      position: relative;
    }
    .btn-trash {
      i {
        color: $grey-color;
      }
      img {
        width: 15px;
        height: auto;
      }
    }
    .price {
      text-align: left;
    }
    .price .num {
      font-size: 1.125rem;
      font-size: 18px;
    }
    .price .text {
      font-size: 14px;
      font-size: 0.875rem;
      color: $grey-color;
      padding-bottom: 15px;
    }
    .trash {
      text-align: right;
    }
    &.mobile {
      li {
        .image {
          background: #fff;
          img {
            width: 80%;
          }
        }
        .name {
          padding-bottom: .5rem !important;
          padding-right: 25px !important;
        }
        .quantity-price {
          .overlay {
            flex: auto;
            max-width: none;
          }
          .quantity {
            .buttons {
              a, span {
                height: 35px;
                width: 35px;
                line-height: 35px;
              }
            }
          }
          .short_name {
            text-align: right;
          }
        }
        .price {
          font-size: 1.125rem;
          margin-bottom: .5rem;
          .text {
            display: inline-block;
            margin: 0;
            padding: 0;
          }
        }
        .trash {
          position: absolute;
          top: 0px;
          right: 0px;
          color: $grey-color;
          a {
            color: $grey-color;
            text-decoration: none;
          }
        }
      }
    }
  }
  .total {
    padding: 20px 20px;
    border: 1px solid $border-color;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    .btn-checkout {
      width: 100%;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      border: 1px solid $green-color;
      text-align: center;
      padding: 1rem 1rem;
      text-decoration: none;
      display: block;
      background: $green-color;
      color: #fff;
      font-size: 18px;
      font-size: 1.125rem;
      &:hover {
        background: $green-color-hover;
        border: 1px solid $green-color-hover;
      }
    }
    .btn-checkout-no {
      width: 100%;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      border: 1px solid $green-color-loaded;
      text-align: center;
      padding: 1rem 1rem;
      text-decoration: none;
      display: block;
      background: $green-color-loaded;
      color: #fff;
      font-size: 18px;
      font-size: 1.125rem;
    }
    .min-order {
      font-size: 1.125rem;
      font-weight: 700;
      text-align: center;
      margin-bottom: .4rem;
    }
    .delivery-list {
      margin-top: 1rem;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      padding-top: 1rem;
      padding-bottom: .5rem;
      margin-bottom: 20px;
      .delivery-list__title {
        font-size: 1.125rem;
        margin-bottom: .7rem;
      }
      .variants {
        .item {
          margin-bottom: .7rem;
          .green {
            color: $green-color;
          }
          .grey_desc {
            color: $grey-color;
            font-size: 0.875rem;
            margin-top: .2rem;
          }
        }
        .region {
          margin-bottom: .7rem;
          a {
            text-decoration: underline;
          }
        }
      }
      .free {
        margin-bottom: .7rem;
        border: 1px solid $green-color-loaded;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        padding: 10px 5px;
        line-height: 1.3;
        text-align: center;
        span {
          color: $green-color;
          font-weight: 700;
        }
      }
    }
  }
  .promocode {
    margin-bottom: 1.5rem;
    form {
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap;
    }
    input[type=text] {
      display: block;
      padding: 0 10px;
      background: #fff;
      border: 1px solid $border-color;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      width: 100%;
      box-sizing: border-box;
      height: 40px;
      line-height: 40px;
        &::placeholder {
            color: $grey-color;
        }
        &:-ms-input-placeholder {
            color: $grey-color;
        }
        &::-ms-input-placeholder {
            color: $grey-color;
        }
    }
    input[type=submit] {
      display: block;
      padding: 0 10px;
      background: $yellow-color;
      border: 0;
      width: 100%;
      box-sizing: border-box;
      height: 40px;
      line-height: 40px;
      font-weight: 500;
    }
    .errored {
      font-size: 0.875rem;
      line-height: 1.3;
      color: $red-color;
      margin-top: .3rem;
    }
    &.active {
      .text {
        font-size: 1.125rem;
      }
      .current {
        font-size: 1.125rem;
        text-align: right;
        text-transform: uppercase;
        a {
          color: $grey-color;
          font-size: 1rem;
          margin-left: .5rem;
        }
      }
    }
  }
  .total-sum {
    .num {
      text-align: right;
    }
    .total-sum__products {
      font-size: 1.125rem;
      margin-bottom: .7rem;
    }
    .total-sum__sale {
      color: $red-color;
      font-size: 1.125rem;
      margin-bottom: .7rem;
    }
    .total-sum__total {
      font-size: 1.125rem;
      font-weight: 700;
      .num {
        font-size: 1.375rem;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #cart {
    h1 {
      font-size: 48px;
      font-size: 3rem;
      text-align: left;
      padding-bottom: 2.5rem;
    }
    .list {
      .name {
        font-size: 24px;
        font-size: 1.5rem;
        line-height: 1.3;
      }
    }
    .sticky-top {
      top: 40px;
    }
  }
}
