#checkout {
  margin-top: 50px;
  margin-bottom: 50px;
  h1 {
    font-size: 32px;
    font-size: 2rem;
    font-family: $title-font;
  }
  h2 {
    font-weight: 700;
    margin-bottom: 2rem;
    &.error {
      color: $red-color;
    }
  }
  .block {
    margin-bottom: 3rem;
      .about-text {
          padding: 1rem;
          display: block;
          box-sizing: border-box;
          background: $grey-background;
          border-radius: 2px;
          overflow: hidden;
          .icon {
              display: block;
              width: 80px;
              font-size: 60px;
              float: left;
              color: #828282;
          }
          .text {
              display: block;
              margin-left: 80px;
              font-size: 1rem;
              line-height: 1.3;
          }
      }
  }
  .order-list {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid $border-color;
    h2 {
      margin-bottom: .5rem;
    }
    .list {
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 1.5rem 0;
        border-bottom: 1px solid $border-color;
        &:last-child {
          border-bottom: none;
        }
        .image {
          img {
            width: 80%;
            height: auto;
          }
        }
        .content {
          .name {
            font-family: $title-font;
            font-size: 18px;
            font-size: 1.125rem;
            font-weight: 500;
            padding-bottom: .5rem !important;
          }
        }
      }
    }
  }
  input[type=text] {
    display: block;
    padding: 20px;
    font-size: 18px;
    border: 1px solid $border-color;
    width: 100%;
  }
  input[type=text]:hover {
    border: 1px solid $grey-color;
  }
  input[type=text]:focus {
    border: 1px solid $green-color;
  }
  input[type=text].apply {
    border: 1px solid $green-color !important;
  }
  input[type=text].error {
    border: 1px solid $red-color !important;
  }
  textarea {
    display: block;
    padding: 20px;
    font-size: 18px;
    border: 1px solid $border-color;
    width: 100%;
    min-height: 150px;
    &:hover {
      border: 1px solid $grey-color;
    }
    &:focus {
      border: 1px solid #1D9C56;
    }
    &.apply {
      border: 1px solid $green-color !important;
    }
    &.error {
      border: 1px solid $red-color !important;
    }
  }
  .checkout-form {
    .form-group-inline {
      display: -webkit-box;
      display: flex;
      flex-wrap: wrap;
    }
    .form-group {
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 30px;
      &:last-child {
        padding-bottom: 0px;
      }
    }
    .desc {
      font-size: 12px;
      color: $grey-color;
    }
  }
  .delivery-location {
    font-size: 18px;
    font-size: 1.125rem;
    a {
      text-decoration: underline;
    }
  }
  .total {
    padding: 20px 20px;
    border: 1px solid $border-color;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    .total-sum {
      .num {
        text-align: right;
      }
      .total-sum__promocode {
        font-size: 1.125rem;
        margin-bottom: .7rem;
        .num {
          text-transform: uppercase;
        }
      }
      .total-sum__products {
        font-size: 1.125rem;
        margin-bottom: .7rem;
      }
      .total-sum__delivery {
        font-size: 1.125rem;
        margin-bottom: .7rem;
        .green {
          color: $green-color;
        }
      }
      .total-sum__sale {
        color: $red-color;
        font-size: 1.125rem;
        margin-bottom: .7rem;
      }
      .total-sum__total {
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 1rem;
        .num {
          font-size: 1.375rem;
        }
      }
    }
  }
  .send-order {
    .btn-oder-send {
      width: 100%;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      border: 1px solid $green-color;
      text-align: center;
      padding: 1rem 1rem;
      text-decoration: none;
      display: block;
      background: $green-color;
      color: #fff;
      font-size: 20px;
      font-size: 1.25rem;
      &:hover {
        background: $green-color-hover;
        border: 1px solid $green-color-hover;
      }
    }
    .errors {
      font-size: 0.875rem;
      line-height: 1.3;
      color: $red-color;
      margin-top: .3rem;
      text-align: center;
    }
    .privacy {
      text-align: center;
      font-size: 0.875rem;
      line-height: 1.3;
      color: $grey-color;
      margin-top: 1rem;
    }
  }
  .pickup-current-point {
    .point {
      padding: 1rem;
      border: 1px solid $border-color;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      .text {
        margin-bottom: 1rem;
        .company {
          font-size: 0.875rem;
          font-weight: 300;
          margin-bottom: .3rem;
        }
        .address {
          font-size: 1.125rem;
          font-weight: 700;
          margin-bottom: .5rem;
        }
        .date_issue {
          font-size: 0.875rem;
          margin-bottom: .5rem;
        }
        .worktime {
          font-size: 0.875rem;
          color: $grey-color;
        }
      }
      .tripdescription {
        margin-bottom: 1rem;
        .title {
          font-size: 1.125rem;
          font-weight: 700;
          margin-bottom: .5rem;
        }
        .text {
          font-size: 0.875rem;
        }
      }
      .phone {
        margin-bottom: 1rem;
        .title {
          font-size: 1.125rem;
          font-weight: 700;
          margin-bottom: .5rem;
        }
        .text {
          font-size: 0.875rem;
        }
      }
      .change_point_btn {
        width: 100%;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        border: 1px solid $green-color;
        text-align: center;
        padding: 1rem 1rem;
        text-decoration: none;
        display: block;
        background: #fff;
        color: $green-color;
        font-size: 20px;
        font-size: 1.25rem;
        &:hover {
          color: #fff;
          background: $green-color-hover;
          border: 1px solid $green-color-hover;
        }
      }
    }
    .pointmap {
      position: relative;
      margin-bottom: .5rem;
      #pointmap {
        width: 100%;
        height: 200px;
      }
      .overlay {
        width: 100%;
        height: 200px;
        background: none;
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #checkout {
    .checkout-form {
      .form-group-inline {
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
        &.padding-bottom {
          padding-bottom: 30px;
        }
      }
      .form-group {
        &.padding-bottom {
          padding-bottom: 30px;
        }
      }
      .form-group.first {
        padding-right: 15px;
      }
      .form-group.last  {
        padding-left: 15px;
      }
      .desc {
        font-size: 12px;
        color: $grey-color;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #checkout {
    h1 {
      font-size: 48px;
      font-size: 3rem;
    }
    .order-list {
      .list {
        li {
          .content {
            .name {
              font-size: 16px;
              font-size: 1rem;
              line-height: 1.3;
            }
          }
        }
      }
    }
  }
}
