#page404 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  .icons {
    font-size: 6.25rem;
    color: $border-color;
    margin-bottom: 1rem;
  }
  .title {
    font-family: $title-font;
    font-size: 32px;
    font-size: 2rem;
    font-family: "WG2", serif;
    margin-bottom: 1rem;
  }
  .text {
    line-height: 1.3;
    a {
      text-decoration: underline;
    }
  }
}

@media screen and (min-width: 992px) {
  #page404 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
}

@media screen and (min-width: 1200px) {
  #page404 {
    margin-top: 4rem;
    margin-bottom: 6rem;
    .icons {
      font-size: 9.375rem;
      color: $border-color;
      margin-bottom: 2rem;
    }
    .title {
      font-size: 48px;
      font-size: 3rem;
    }
  }
}