.mobile-menu-btn {
  color: #000;
}

.mobile-menu-btn .hamb {
  width: 18px;
  height: 12px;
}

.mobile-menu-btn .part {
  position: relative;
  display: block;
  width: 18px;
  height: 2px;
  margin-top: 3px;
  border-radius: 10px;
  background-color: $black-color;
}

.mobile-menu__side {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0,0,0,.3);
  webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  &.active {
    display: block;
    pointer-events: auto;
  }
}

.mobile-menu__block {
  position: fixed;
  top: 0px;
  left: 0px;
  background: #fff;
  width: 80%;
  max-width: 280px;
  height: 100%;
  z-index: 1001;
  -o-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  box-sizing: border-box;
  padding: 1rem 1.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  &.active {
    -o-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.mobile-menu__block.active {
  -o-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu__block_menu {
  ul {
    display: block;
    margin: 0;
    padding: 0;
    li {
      display: block;
      margin: 0;
      padding: 0;
      a {
        display: block;
        padding: 1rem .5rem;
        border-bottom: 1px solid $border-color;
        &.parent {
          font-size: 1.125rem;
          font-weight: 700;
          background: url("../../../assets/frontend/images/down-arrow.svg") no-repeat right center;
          background-size: 8px auto;
          &.active {
            color: $green-color;
            background: url("../../../assets/frontend/images/up-arrow.svg") no-repeat right center;
            background-size: 8px auto;
          }
        }
        &.main-link {
          font-size: 1.125rem;
          font-weight: 700;
        }
      }
    }
    &.sub {
      li {

      }
    }
  }
}

.mobile-menu__block_location {
  color: $grey-color;
  padding: 0 0 1rem 0;
  border-bottom: 1px solid $border-color;
  .row {
    align-items: center;
  }
  .location {
    i {
      font-size: 14px;
      font-size: 0.875rem;
      margin-right: 5px;
    }
    a {
      color: $grey-color;
    }
  }
}