.header__logo {
  text-align: center;
}

.header__image {
  width: 100%;
  height: auto;
}

.header__imagemini {
  height: 30px;
  width: auto;
}

.pc-logo {
  .image {
    padding-right: 10px;
    img {
      max-width: 99%;
    }
  }
  .text {
    display: block;
    text-align: left;
    .name {
      display: block;
      font-family: $title-font;
      font-weight: 700;
      font-size: 1.75rem;
      margin-bottom: 2px;
    }
    .slogan {
      display: block;
      font-size: 0.688rem;
    }
  }
}

@media screen and (min-width: 992px) {
  .header__logo {
    text-align: left;
  }
}

@media screen and (min-width: 1200px) {
  .header__image {
    width: 90%;
  }
}