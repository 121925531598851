.global-loading-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: rgba(255,255,255,0.6);
  z-index: 2000;
  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    background: url("../../../../assets/frontend/images/loading-green.svg") no-repeat center center;
    background-size: 50px 50px;
    height: 50px;
    width: 50px;
    margin-left: -25px;
    margin-top: -25px;
    z-index: 2001;
  }
}