.twobanners {
  border-bottom: 1px dotted $border-color;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
  .twobanners__link {
    display: block;
    max-height: 263px;
    &.first-child {
      margin-bottom: 30px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  &.bottom {
    border-bottom: 0;
    padding-bottom: 0;
  }
  &.top {
    margin-top: 2rem;
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 576px) {
  .twobanners {
    border-bottom: none;
    margin-bottom: 5rem;
    padding-bottom: 0;
    &.top {
      margin-bottom: 0;
    }
  }
}

@media screen and (min-width: 992px) {
  .twobanners__link {
    margin-bottom: 0 !important;
  }
}