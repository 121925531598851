.products-block__carusel-item {
    padding-left: 15px;
    padding-right: 15px;
}

.product-vertical {
    border-bottom: 1px dotted $form-grey-color;
    position: relative;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    padding-left: 25px;
    padding-right: 25px;

    &:hover {
        -webkit-transition: .3s ease;
        -moz-transition: .3s ease;
        -ms-transition: .3s ease;
        -o-transition: .3s ease;
        transition: .3s ease;

        .product-vertical__name {
            -webkit-transition: .3s ease;
            -moz-transition: .3s ease;
            -ms-transition: .3s ease;
            -o-transition: .3s ease;
            transition: .3s ease;

            a {
                color: $green-color;
                -webkit-transition: .3s ease;
                -moz-transition: .3s ease;
                -ms-transition: .3s ease;
                -o-transition: .3s ease;
                transition: .3s ease;
            }
        }
    }

    .product-vertical__preorder {
        text-align: center;

        a {
            display: inline-block;
            margin-right: -4px;
        }
    }

    .product-vertical__preorder-text {
        text-align: center;
        font-size: 0.875rem;
        margin-bottom: 5px;
        color: $grey-color;
    }

    .product-vertical__preoder {
        color: $red-color;
        text-align: center;
        font-size: 1rem;
        margin-top: .2rem;
    }
}

.product-vertical__image {
    background: #fff;
    margin-bottom: 1rem;
    position: relative;
    text-align: left;
    position: relative;

    img {
        width: 100%;
        height: auto;

        &.preorder {
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
        }
    }

    .sticker {
        padding: 10px;
        box-sizing: border-box;
        font-size: 1.125rem;
        -webkit-border-radius: 24px;
        -moz-border-radius: 24px;
        border-radius: 24px;
        position: absolute;
        bottom: 5px;
        left: 50%;
        font-weight: 700;
        background: #fff;
        text-align: center;

        &.hit {
            background: $yellow-color;
            color: $black-color;
            width: 184px;
            margin-left: -92px;
        }

        &.new {
            background: $green-color;
            color: #fff;
            width: 184px;
            margin-left: -92px;
        }

        &.hot {
            color: #fff;
            width: 184px;
            margin-left: -92px;
            background: $red-color;
        }

        &.liquidation {
            color: #fff;
            width: 184px;
            margin-left: -92px;
            background: $black-red-color;
        }

        &.best {
            background: #9c1d6b;
            color: #fff;
            width: 184px;
            margin-left: -92px;
        }

        &.exclusively {
            background: #9c591d;
            color: #fff;
            width: 184px;
            margin-left: -92px;
        }

        &.preorder {
            width: 184px;
            margin-left: -92px;
            background: $olive-color;
            color: #fff;
        }

        &.antiprice {
            background: $yellow-color;
            color: $black-color;
            width: 184px;
            margin-left: -92px;
        }
    }
}

.product-vertical__type {
    /*color: #777777;*/
    margin-bottom: .5rem;
    text-align: center;
}

.product-vertical__name {
    font-family: $title-font;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    line-height: 1.15;
    width: 100%;
    text-align: center;
    /*font-weight: 500;*/
    a {
        &:hover {
            color: $green-color;
        }
    }
}

.product-vertical__desc {
    font-size: 14px;
    font-size: 0.875rem;
    line-height: 1.3;
    margin-bottom: 1rem;
    text-align: center;

    a {
        color: $black-color;
        display: inline-block;
        border-bottom: 1px dotted $black-color;
        cursor: pointer;

        &:hover {
            color: $green-color;
            border-bottom: 1px solid $green-color;
        }
    }
}

.product-vertical__pack {
    color: #9C531D;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    text-align: center;
}

.product-vertical__variants {
    margin-bottom: 1rem;
}

.product-vertical__favorite {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 0px;
    right: 0px;
    display: none;
}

.product-vertical__favorite svg {
    width: 20px;
    height: 20px;
    fill: #C3C3C3;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.product-vertical__favorite.active svg {
    fill: #ED71AE;
}

.product-vertical__favorite:hover svg {
    fill: #e32080;
}

.product-vertical__bottom {
    height: 42px;
    position: relative;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;

    &.preorder {
        height: auto;
        margin-bottom: .5rem;
    }
}

.product-vertical__buy {
    display: block;
    vertical-align: middle;
    margin-left: 20px;
}

.product-vertical__price {
    display: block;
    vertical-align: middle;
}

.product-vertical__sales {
    position: absolute;
    top: 5px;
    left: 15px;
}

@media screen and (min-width: 576px) {
    .products-block {
        margin-bottom: 5rem;

        &.favorite-list {
            margin-bottom: 0;
        }
    }

    .product-vertical {
        border-bottom: none;
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 992px) {
    .product-vertical {
        margin-bottom: 4rem;
    }
    .product-vertical.nomargin {
        margin-bottom: 4rem;
    }
}

@media screen and (min-width: 1200px) {
    .productverticalcol {
        border-top: 1px dotted $form-grey-color;
        padding-top: 4rem;
    }
    .product-vertical__image {
        text-align: center;

        img {
            width: 100%;
            height: auto;
        }

        &:hover {
            img {
                margin-left: 2px;
            }
        }
    }
    .product-vertical__name {
        font-size: 26px;
        font-size: 1.625rem;
        line-height: 1.75rem;
        /*font-weight: 400;*/
    }
}

@media screen and (min-width: 1500px) {
    .product-vertical__image {
        img {
            width: 90%;
            height: auto;
        }
    }
}
