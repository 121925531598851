.similar-products {
  margin-bottom: 4rem
}

@media screen and (min-width: 1200px) {
  .similar-products {
    margin-bottom: 4.5rem;
    &:last-child {
      margin-bottom: 5rem;
    }
  }
}