.modal-login {
  .form-group {
    &:not(:last-child) {
      margin-bottom: .5rem;
    }
    .desc {
      margin-bottom: 5px;
    }
    input[type=text] {
      font-size: 20px;
      font-size: 1.25rem;
    }
  }
  input[type=submit] {
    display: block;
    width: 100%;
    box-sizing: border-box;
    height: 60px;
    line-height: 60px;
    padding: 0;
    font-size: 20px;
    font-size: 1.25rem;
  }
  .submit span {
    display: block;
    width: 100%;
    box-sizing: border-box;
    height: 60px;
    line-height: 60px;
    padding: 0;
    font-size: 20px;
    font-size: 1.25rem;
  }
  .noCustomer {
    font-size: 0.875rem;
    margin-bottom: .5rem;
    .noCustomer-text {
      margin-bottom: .5rem;
    }
    .noCustomer-link {
      a {
        color: $green-color;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .test-period {
    font-size: 0.75rem;
    line-height: 1.3;
  }
  .modal-login-error {
    color: $red-color;
  }
}