.row-pickup {
  &.hide {
    display: none;
  }
  #map {
    width:100%;
    height:500px;
  }
  .pickup-map {
    position: relative;
    .pickup-map-loading {
      background: #fafafa url("../../../../assets/frontend/images/loading-green.svg") no-repeat center center;
      background-size: 50px 50px;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 10;
    }
    .pickup-map-ballon {
      max-width: 200px;
      .text {
        margin-bottom: 1rem;
        .company {
          font-size: 0.875rem;
          font-weight: 300;
          margin-bottom: .3rem;
        }
        .address {
          font-size: 1.125rem;
          font-weight: 700;
          margin-bottom: .5rem;
        }
        .date_issue {
          font-size: 0.875rem;
          margin-bottom: .5rem;
        }
        .worktime {
          font-size: 0.875rem;
          color: $grey-color;
        }
      }
      .tripdescription {
        margin-bottom: 1rem;
        .title {
          font-size: 1.125rem;
          font-weight: 700;
          margin-bottom: .5rem;
        }
        .text {
          font-size: 0.875rem;
          height: 60px;
          overflow-y: auto;
        }
      }
      #pickup-map-ballon-button-change,
      .pickup-map-ballon-button-change {
        display: block;
        cursor: pointer;
        width: 100%;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        border: 1px solid $green-color;
        text-align: center;
        padding: 1rem 1rem;
        text-decoration: none;
        display: block;
        background: #fff;
        color: $green-color;
        font-size: 18px;
        font-size: 1.125rem;
        &:hover {
          color: #fff;
          background: $green-color-hover;
          border: 1px solid $green-color-hover;
        }
      }
    }
  }
}

@media screen and (min-width: 360px) {
  .row-pickup {
    .pickup-map {
      .pickup-map-ballon {
        max-width: 250px;
      }
    }
  }
}