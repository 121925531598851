.grey-button {
  display: inline-block;
  background: $border-color;
  color: $black-color;
  box-sizing: border-box;
  padding: .5rem;
  text-align: center;
  &:hover {
    background: #D6D6D6;
  }
}