.mainmenu {
  padding-bottom: 1.15rem;
  z-index: 100;
  position: relative;
  background: #fff;
  box-shadow: 0 6px 11px -6px rgba(0,0,0,.1);
}

.catalog-menu__btn {
  display: block;
  color: $green-color;
  height: 52px;
  line-height: 48px;
  text-align: center;
  padding: 0 20px 0 20px;
  text-decoration: none;
  transition: all .12s ease-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: 2px solid $green-color;
  font-size: 18px;
  background: #fff;
  overflow: hidden;
  &:hover {
    background: $green-color;
    color: #fff;
  }
  &.active,
  &.active:hover {
    background: $green-color;
    color: #fff;
  }
}

@media screen and (min-width: 1500px) {
  .catalog-menu__btn {
    width: 80%;
  }
}

.catalog-menu__btn .hamb-wrapper {
  display: inline-block;
  margin-right: 10px;
}

.catalog-menu__btn .hamb {
  width: 18px;
  height: 12px;
}

.catalog-menu__btn .part {
  position: relative;
  display: block;
  width: 18px;
  height: 2px;
  margin-top: 3px;
  transition: .3s;
  border-radius: 10px;
  background-color: $green-color;
}

.catalog-menu__btn.active .part,
.catalog-menu__btn:hover .part {
  background-color: #fff;
}

.catalog-menu__btn.active .part:first-of-type {
  -webkit-transform: translateY(5px) translateZ(0) rotate(45deg);
  transform: translateY(5px) translateZ(0) rotate(45deg);
}

.catalog-menu__btn.active .part:nth-of-type(2) {
  opacity: 0;
}

.catalog-menu__btn.active .part:nth-of-type(3) {
  -webkit-transform: translateY(-5px) translateZ(0) rotate(-45deg);
  transform: translateY(-5px) translateZ(0) rotate(-45deg);
}

.mainmenu .row {
  align-items: center;
}

.mainmenu-infomenu {
  margin: 0;
  padding: 0;
  text-align: right;
  li {
    display: inline-block;
  }
  a:hover,
  a:focus {
    color: $green-color;
    border-color: $green-color;
  }
}

.mainmenu-infomenu__item1 a,
.mainmenu-infomenu__item4 a {
  border-bottom: 1px solid $black-color;
  padding-bottom: 1px;
}

.mainmenu-infomenu__item2 a,
.mainmenu-infomenu__item3 a {
  span {
    padding-bottom: 1px;
  }
  .border {
    border-bottom: 1px dotted $black-color;
  }
}

.mainmenu-infomenu__item1,
.mainmenu-infomenu__item2,
.mainmenu-infomenu__item3 {
  margin-right: 2.6rem;
}

.mainmenu-infomenu__item4 {
  background: url("../../../assets/frontend/images/mainmenu-star.svg") no-repeat left center;
  background-size: 15px;
  padding-left: 20px;
  margin-right: -4px;
}

@media screen and (min-width: 1200px) {
  .mainmenu {
    margin-bottom: 2rem;
  }
}