.topbanner-static {
  margin-top: 2rem;
  img {
    max-width: 100%;
  }
}

.topbanners-static-for-sale {
  .item {
    margin-top: 2rem;
      a {
          display: block;
          position: relative;
          img {
              width: 100%;
              height: auto;
          }
          .date {
              display: block;
              position: absolute;
              bottom: 20px;
              right: 20px;
              background: $green-color;
              color: #fff;
              padding: 10px;
              -webkit-border-radius: 2px;
              -moz-border-radius: 2px;
              border-radius: 2px;
              font-size: 0.875rem;
          }
      }
  }
}

@media screen and (min-width: 768px) {
  .topbanner-static {
    margin-top: 0px;
  }
}
