#product-reviews {
  margin-bottom: 4rem;
  .pack {
    text-align: center;
    margin-bottom: 1rem;
    img {
      max-width: 60%;
      &.preorder {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
    }
    .attention {
        margin-top: .5rem;
        font-size: 0.875rem;
        color: #292E1E;
        padding: 0.5rem;
        background: $grey-background;
        border-radius: 2px;
        line-height: 1.3;
        box-sizing: border-box;
        text-align: left;
    }
  }
  .title {
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1;
    font-family: $title-font;
    margin-bottom: 1.5rem;
    text-align: left;
  }
  .list {
    .item {
      border-bottom: 1px dotted $form-grey-color;
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      &.hide {
        display: none;
      }
      &.show {
        display: block;
      }
      .name {
        font-size: 18px;
        font-size: 1.125rem;
        font-weight: 700;
        margin-bottom: 1rem;
      }
      .param {
        color: $grey-color;
        font-size: 14px;
        font-size: 0.875rem;
        margin-bottom: 1rem;
      }
      .text {
        line-height: 1.3;
      }
      .product-name {
          color: $grey-color;
          font-size: 0.875rem;
          font-style: italic;
          margin-top: 1rem;
      }
    }
    .item:last-child {
      margin-bottom: 0px;
    }
    .more-reviews {
      text-align: center;
      a {
        display: inline-block;
        cursor: pointer;
        font-size: 1.125rem;
        color: $black-color;
        border-bottom: 1px dashed $black-color;
        padding-bottom: .2rem;
        &.hide {
          display: none;
        }
        &:hover {
          color: $green-color;
          border-bottom: 1px solid $green-color;
        }
      }
    }
      .review-images {
          .review-image {
              width: 150px;
              display: inline-block;
              margin-top: 1rem;
              margin-right: .5rem;
              vertical-align: top;
              img {
                  max-width: 100%;
                  height: auto;
              }
          }
      }
  }
  .no-reviews {
    text-align: center;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 768px) {
  #product-reviews {
    .pack {
      img {
        max-width: 100%;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #product-reviews {
    margin-bottom: 4.5rem;
    .pack {
      padding-left: 120px;
    }
    .part-title {
      text-align: center;
      padding-right: 120px;
    }
    .list {
      padding-right: 120px;
      .item {
        .text {
          font-size: 1.125rem;
          line-height: 1.5;
        }
      }
    }
    .no-reviews {
      padding-right: 120px;
      margin-bottom: 2rem;
      font-size: 1.125rem;
    }
    &.nopackimage {
      .part-title {
        text-align: center;
        padding-right: 0;
      }
      .list {
        padding-left: 120px;
        padding-right: 120px;
      }
      .no-reviews {
        padding-right: 0;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  #product-reviews {
    .pack {
      padding-left: 200px;
    }
    .part-title {
      padding-right: 200px;
    }
    .list {
      padding-right: 200px;
    }
    .no-reviews {
      padding-right: 200px;
    }
    &.nopackimage {
      .part-title {
        padding-right: 0;
      }
      .list {
        padding-right: 200px;
        padding-left: 200px;
      }
      .no-reviews {
        padding-right: 0;
      }
    }
  }
}
