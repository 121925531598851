.quantity-block {
  .quantity-price {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 0.5rem;
    .overlay {
      -webkit-box-flex: 0;
      flex: 0 0 150px;
      max-width: 150px;
      position: relative;
    }
  }

  .quantity-text {
    font-size: 	0.875rem;
    font-size: 14px;
    margin-bottom: 5px;
    color: #777777;
  }

  .quantity {
    .text {
      font-size: 	0.875rem;
      font-size: 14px;
      margin-bottom: 5px;
      color: #777777;
    }
    .buttons {
      display: inline-block;
      border: 1px solid #EEEEEE;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      .minus,
      .num,
      .plus {
        display: inline-block;
        width: 42px;
        height: 40px;
        text-align: center;
        line-height: 40px;
      }
      a {
        color: #1D9C56;
      }
      .disable {
        cursor: default;
        color: #828282;
      }
    }
  }
}