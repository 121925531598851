.input-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 18px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ .checkmark {
      background-color: $green-color;
    }
    &:checked ~ .checkmark:after {
      display: block;
    }
  }
  .green-color {
    color: $green-color;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $border-color;
    border-radius: 50%;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -ms-transition: .3s ease;
    -o-transition: .3s ease;
    transition: .3s ease;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin-left: -4px;
    margin-top: -4px;
    border-radius: 50%;
    background: white;
    -webkit-transition: .3s ease;
    -moz-transition: .3s ease;
    -ms-transition: .3s ease;
    -o-transition: .3s ease;
    transition: .3s ease;
  }
  &:hover input ~ .checkmark {
    background-color: $grey-color;
  }
  &:hover input:checked ~ .checkmark {
    background-color: $green-color;
  }
  &:hover input ~ .checkmark:after {
    display: block;
  }
  .text {
    display: block;
    margin-bottom: .3rem;
  }
  .date {
    display: block;
    color: $grey-color;
    font-size: 0.875rem;
    line-height: 1.3;
  }
  .delay {
    display: block;
    font-size: 0.875rem;
    color: $black-color;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    line-height: 1.3;
    box-sizing: border-box;
    margin-top: .5rem;
    max-width: 430px;
  }
  .free {
    display: block;
    color: $green-color;
    font-size: 0.875rem;
    line-height: 1.3;
  }
}