@font-face {
    font-family: 'WG2';
    src: url('WG2-Italic.eot');
    src: url('WG2-Italic.eot?#iefix') format('embedded-opentype'),
        url('WG2-Italic.woff2') format('woff2'),
        url('WG2-Italic.woff') format('woff'),
        url('WG2-Italic.ttf') format('truetype'),
        url('WG2-Italic.svg#WG2-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'WG2';
    src: url('WG2-BoldItalic.eot');
    src: url('WG2-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('WG2-BoldItalic.woff2') format('woff2'),
        url('WG2-BoldItalic.woff') format('woff'),
        url('WG2-BoldItalic.ttf') format('truetype'),
        url('WG2-BoldItalic.svg#WG2-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'WG2';
    src: url('WG2-Bold.eot');
    src: url('WG2-Bold.eot?#iefix') format('embedded-opentype'),
        url('WG2-Bold.woff2') format('woff2'),
        url('WG2-Bold.woff') format('woff'),
        url('WG2-Bold.ttf') format('truetype'),
        url('WG2-Bold.svg#WG2-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WG2';
    src: url('WG2-Regular.eot');
    src: url('WG2-Regular.eot?#iefix') format('embedded-opentype'),
        url('WG2-Regular.woff2') format('woff2'),
        url('WG2-Regular.woff') format('woff'),
        url('WG2-Regular.ttf') format('truetype'),
        url('WG2-Regular.svg#WG2-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

