.popular-categories {
  margin-bottom: 5rem;
}

.popular-category {
  background: #FAF4E7;
  padding: 2rem 1rem;
  text-align: center;
  position: relative;
  margin-bottom: 40px;
  .popular-category__image {
    background: #fff;
    height: 224px;
    margin-bottom: 1rem;
  }
  .popular-category__image img {
    width: auto;
    height: 100%;
  }
  .popular-category__title {
    font-size: 28px;
    margin-bottom: 1rem;
    font-family: $title-font;
  }
  .popular-category__text {
    margin-bottom: 2rem;
    line-height: normal;
  }
  .popular-category__bottom {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
  }
  .popular-category__price,
  .popular-category__button {
    flex: 100%;
  }
  .popular-category__price {
    text-align: right;
    padding-right: 20px;
    font-size: 18px;
    font-size: 1.125rem;
  }
  .popular-category__button {
    text-align: center;
  }
  .popular-category__button a {
    display: inline-block;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #2E2D2A;
    color: #2E2D2A;
    padding: .5rem 1rem;
  }

  .popular-category__button a:hover {
    background: #2E2D2A;
    color: #ffffff;
  }
}
.popular-category.last {
  margin-bottom: 0;
}

.popular-category.item2 {
  background: #E7FAEF;
  .popular-category__button a {
    border: 1px solid #2A2E2C;
    color: #2A2E2C;
  }
  .popular-category__button a:hover {
    background: #2A2E2C;
    color: #ffffff;
  }
}

.popular-category.item3 {
  background: #F9FAE7;
  .popular-category__button a {
    border: 1px solid #2E2E2A;
    color: #2E2E2A;
  }
  .popular-category__button a:hover {
    background: #2E2E2A;
    color: #ffffff;
  }
}

.popular-category.item4 {
  background: #E9FAE7;
  .popular-category__button a {
    border: 1px solid #2B2E2A;
    color: #2B2E2A;
  }
  .popular-category__button a:hover {
    background: #2B2E2A;
    color: #ffffff;
  }
}

.popular-category.item5 {
  background: #FAEFE7;
  .popular-category__button a {
    border: 1px solid #2E2C2A;
    color: #2E2C2A;
  }
  .popular-category__button a:hover {
    background: #2E2C2A;
    color: #ffffff;
  }
}

.popular-category.item6 {
  background: #FAE7EF;
  .popular-category__button a {
    border: 1px solid #2E2A2C;
    color: #2E2A2C;
  }
  .popular-category__button a:hover {
    background: #2E2A2C;
    color: #ffffff;
  }
}

.popular-category.item7 {
  background: #faebe7;
  .popular-category__button a {
    border: 1px solid #2E2A2C;
    color: #2E2A2C;
  }
  .popular-category__button a:hover {
    background: #2E2A2C;
    color: #ffffff;
  }
}

.popular-category.item8 {
  background: #f4fae7;
  .popular-category__button a {
    border: 1px solid #2E2A2C;
    color: #2E2A2C;
  }
  .popular-category__button a:hover {
    background: #2E2A2C;
    color: #ffffff;
  }
}

.popular-category.item9 {
  background: #e7faf8;
  .popular-category__button a {
    border: 1px solid #2E2A2C;
    color: #2E2A2C;
  }
  .popular-category__button a:hover {
    background: #2E2A2C;
    color: #ffffff;
  }
}

@media screen and (min-width: 992px) {
  .popular-category.item7,
  .popular-category.item8,
  .popular-category.item9 {
    margin-bottom: 0px;
  }
  .popular-category {
    .popular-category__text {
      min-height: 140px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .popular-category {
    .popular-category__text {
      min-height: 120px;
    }
  }
}

@media screen and (min-width: 1500px) {
  .popular-category {
    .popular-category__text {
      min-height: 80px;
    }
  }
}