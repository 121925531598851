#feedback-delivery {
    margin-bottom: 3rem;
    .text {
        p {
            margin-bottom: 1.5rem;
        }
    }
    .form {
        .form-group {
            &.padding-bottom {
                padding-bottom: 30px;
            }
            input[type=text] {
                display: block;
                padding: 20px;
                font-size: 18px;
                border: 1px solid #EBEBEB;
                width: 100%;
            }
            input[type=submit] {
                width: 200px;
                border-radius: 2px;
                border: 1px solid #1D9C56;
                text-align: center;
                padding: 1rem 1rem;
                text-decoration: none;
                display: block;
                background: #1D9C56;
                color: #fff;
                font-size: 1.25rem;
            }
            textarea {
                display: block;
                padding: 20px;
                font-size: 18px;
                border: 1px solid #EBEBEB;
                width: 100%;
                min-height: 150px;
            }
        }
    }
    .alert-success {
        padding: 20px;
        background: $green-color;
        color: #fff;
        font-size: 18px;
        border-radius: 4px;
    }
    .privacy {
        text-align: center;
        font-size: 0.875rem;
        line-height: 1.3;
        color: #828282;
        margin-top: 1rem;
    }
}

@media screen and (min-width: 1200px) {
    #feedback-delivery {
        .text {
            font-size: 1.375rem;
            p {
                padding-left: 120px;
                padding-right: 120px;
                line-height: 1.3;
            }
        }
        .form {
            padding-left: 120px;
            padding-right: 120px;
        }
    }
}
