.pagination {
  &.bottom {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
  }
    &.center {
        text-align: center;
    }
  ul {
    display: block;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-right: 0.3rem;
      padding: 0;
      a {
        display: block;
        height: 40px;
        width: 40px;
        text-align: center;
      }
      span {
        display: block;
        height: 40px;
        line-height: 40px;
        width: 40px;
        text-align: center;
        border: 1px solid $green-color;
        color: $green-color;
        @include default-border-radius();
      }
    }
  }
    &.no-prev [aria-label='pagination.previous'] {
        display: none !important;
    }
    &.no-next [aria-label='pagination.next'] {
        display: none !important;
    }
}

.pagination-row {
  align-items: center;
  .count-products {
    color: $grey-color;
    text-align: center;
  }
}

@media screen and (min-width: 992px) {
  .pagination {
    &.bottom {
      text-align: left;
    }
  }
  .pagination-row {
    .count-products {
      text-align: right;
    }
  }
}
