#coffee-params {
  margin-top: -2rem;
  margin-bottom: 4rem;
  ul {
    display: block;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    li {
      display: block;
      margin: 0;
      line-height: 1.5;
      font-style: italic;
      span {
        font-style: normal;
        font-weight: 700;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  #coffee-params {
    ul {
      width: 320px;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 768px) {
  #coffee-params {
    ul {
      width: auto;
      margin: 0;
    }
  }
}

@media screen and (min-width: 1200px) {
  #coffee-params {
    ul {
      padding-left: 120px;
      padding-right: 120px;
      li {
        font-size: 18px;
        font-size: 1.125rem;
        line-height: 1.8;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #coffee-params {
    ul {
      padding-left: 200px;
      padding-right: 200px;
    }
  }
}