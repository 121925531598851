#description_images {
    display: flex;
    flex-wrap: wrap;
    margin-right: -25px;
    margin-left: -25px;
    .image {
        position: relative;
        width: 100%;
        padding-right: 25px;
        padding-left: 25px;
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 50px;
        img {
            max-width: 100%;
        }
    }
}

@media screen and (min-width: 768px) {
    #description_images {
        .image {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
