.grey-bordered-button {
  display: inline-block;
  @include default-border-radius();
  border: 1px solid $form-grey-color;
  text-align: center;
  padding: .5rem 1rem;
  text-decoration: none;
  color: $form-grey-color;
  vertical-align: top;
  visibility: visible;
  &:hover {
    background: $green-color;
    color: #fff;
  }
  i {
    font-size: 14px;
    font-size: 0.875rem;
  }
}