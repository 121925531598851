#company {
  margin-bottom: 3rem;
  .banner {
    margin-bottom: 2rem;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;
    background: url("/images/frontend/company.jpg") no-repeat center center;
    height: 400px;
  }
  .page-title {
    margin-top: 2rem;
    margin-bottom: 2rem;
    h1 {
      padding-bottom: 0;
    }
  }
  .text {
    line-height: 1.5;
    p {
      margin-bottom: 1.5rem;
    }
  }
}

@media screen and (min-width: 1200px) {
  #company {
    margin-bottom: 5rem;
    .page-title {
      h1 {
        padding-bottom: 0;
      }
    }
    .text {
      font-size: 22px;
      font-size: 1.375rem;
      p {
        padding-left: 120px;
        padding-right: 120px;
      }
    }
  }
}