#deliveries {
  background: $border-color;
  margin-bottom: 3rem;
  margin-top: -2rem;
  h1 {
    font-size: 32px;
    font-size: 2rem;
    font-family: $title-font;
    font-weight: normal;
    padding-bottom: 2rem;
    text-align: center;
  }
  .container {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .list {
    .item {
      background: #fff;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      padding: 1rem;
      -webkit-box-shadow: 0 1px 11px rgb(204 204 204 / 6%);
      -moz-box-shadow: 0 1px 11px rgb(204 204 204 / 6%);
      box-shadow: 0 1px 11px rgb(204 204 204 / 6%);
      margin-bottom: 1rem;
      min-height: 139px;
      &.last {
        margin-bottom: 0;
      }
      .text {
        font-size: 1.5rem;
        line-height: 1.3;
        margin-bottom: .5rem;
      }
      .desc {
        line-height: 1.3;
        margin-bottom: .5rem;
      }
      .price {
        margin-bottom: .5rem;
      }
      .free {
        color: $green-color;
        margin-bottom: .5rem;
      }
      .time {
        font-size: 0.875rem;
        color: $grey-color;
      }
      .delay {
        font-size: 0.875rem;
        color: $black-color;
        margin-top: .5rem;
        padding: .5rem;
        background: $grey-background;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        line-height: 1.3;
        box-sizing: border-box;
      }
    }
  }
  .current-city-label {
    margin-bottom: 1rem;
  }
  .current-city {
    margin-bottom: 1rem;
    a {
      cursor: pointer;
      background: #ffffff;
      border: 1px solid $border-color;
      @include default-border-radius();
      text-align: left;
      position: relative;
      height: 60px;
      line-height: 60px;
      padding-left: 20px;
      padding-right: 20px;
      overflow: hidden;
      width: 100%;
      display: block;
      box-sizing: border-box;
      -webkit-box-shadow: 0 1px 11px rgb(204 204 204 / 6%);
      -moz-box-shadow: 0 1px 11px rgb(204 204 204 / 6%);
      box-shadow: 0 1px 11px rgb(204 204 204 / 6%);
    }
  }
}

#deliveries-advantages {
  margin-bottom: 3rem;
  .h1 {
    font-size: 32px;
    font-size: 2rem;
    font-family: $title-font;
    font-weight: normal;
    padding-bottom: 2rem;
    text-align: center;
  }
  .item {
    text-align: center;
    margin-bottom: 2rem;
    .icon {
      margin-bottom: 1rem;
      overflow: hidden;
      img {
        width: 50px;
        &.item-3 {
          width: 40px;
        }
      }
    }
    .title {
      font-size: 1.5rem;
      line-height: 1.3;
      margin-bottom: 0.5rem;
    }
    .text {
      line-height: 1.3;
    }
  }
}

#payment {
  margin-bottom: 3rem;
  .h1 {
    font-size: 32px;
    font-size: 2rem;
    font-family: $title-font;
    font-weight: normal;
    padding-bottom: 2rem;
    text-align: center;
  }
  .item {
    text-align: center;
    margin-bottom: 2rem;
    .icon {
      margin-bottom: 1rem;
      overflow: hidden;
      img {
        height: 40px;
        &.item-1 {
          height: 55px;
        }
      }
    }
    .title {
      font-size: 1.5rem;
      line-height: 1.3;
      margin-bottom: 0.5rem;
    }
    .text {
      line-height: 1.3;
    }
  }
}

@media screen and (min-width: 1200px) {
  #deliveries {
    margin-bottom: 5rem;
    h1 {
      font-size: 48px;
      font-size: 3rem;
      padding-bottom: 4rem;
    }
    .current-city {
      a {
        font-size: 1.125rem;
      }
    }
  }
  #deliveries-advantages {
    margin-bottom: 5rem;
    .h1 {
      font-size: 48px;
      font-size: 3rem;
      padding-bottom: 4rem;
    }
    .item {
      text-align: center;
      margin-bottom: 0;
      .icon {
        margin-bottom: 1rem;
        height: 120px;
        img {
          width: auto;
          height: 100px;
          &.item-3 {
            width: auto;
            height: 100px;
          }
        }
      }
    }
  }
  #payment {
    margin-bottom: 5rem;
    .h1 {
      font-size: 48px;
      font-size: 3rem;
      padding-bottom: 4rem;
    }
    .item {
      text-align: center;
      margin-bottom: 0;
      .icon {
        margin-bottom: 1rem;
        height: 120px;
        img {
          width: auto;
          height: 80px;
          &.item-1 {
            width: auto;
            height: 100px;
          }
        }
      }
    }
  }
}
