/* Generated by ParaType (http://www.paratype.com)*/
/* Font PT Root UI: Copyright ? ParaType, 2018. All rights reserved.*/


@font-face {
    font-family: 'WG3';
    src: url('WG3.eot');
    src:
            url('WG3.eot?#iefix') format('embedded-opentype'),
            url('WG3.woff') format('woff'),
            url('WG3.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}