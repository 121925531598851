#modalAddCart {
  position: fixed;
  right: 50%;
  margin-right: -150px;
  bottom: 0;
  overflow: auto;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.12), 0 9px 46px 0 rgba(0,0,0,.06);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #fff;
  transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -o-transform: translateY(100%);
  transform: translateY(100%);
  transition: .35s linear;
  max-height: 100%;
  width: 300px;
  z-index: 200;
  &.active {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition-duration: .35s;
    -o-transition-duration: .35s;
    -moz-transition-duration: .35s;
    transition-duration: .35s;
    -webkit-transition-timing-function: cubic-bezier(.68,-.55,.285,1.55);
    -o-transition-timing-function: cubic-bezier(.68,-.55,.285,1.55);
    -moz-transition-timing-function: cubic-bezier(.68,-.55,.285,1.55);
    transition-timing-function: cubic-bezier(.68,-.55,.285,1.55);
    bottom: 38px;
  }
  .outter {
    position: relative;
    margin: 0 auto;
    padding: 20px 24px 24px;
  }
  .title {
    font-size: 16px;
    font-size: 1rem;
    /*padding-bottom: 20px;*/
    font-weight: 700;
    text-align: center;
    color: $green-color;
  }
  .image {
    width: 50px;
    height: 50px;
    float: left;
    img {
      width: 100%;
      height: auto;
    }
  }
  .content {
    overflow: hidden;
    .text {
      margin-left: 70px;
      .name {
        padding-bottom: 10px;
      }
      .quantity {
        font-size: 0.875rem;
        font-size: 14px;
        padding-bottom: 10px;
        color: #9C531D;
      }
      .summ {
        font-size: 1.125rem;
        font-size: 18px;
      }
    }
  }
}

@media screen and (min-width: 576px) {
  #modalAddCart {
    margin-right: -187px;
    width: 375px;
    .title {
      font-size: 20px;
      font-size: 1.25rem;
    }
    .image {
      width: 100px;
      height: 100px;
    }
    .content {
      .text {
        margin-left: 120px;
      }
    }
  }
}