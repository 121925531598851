.part-title {
  text-align: center;
  margin-bottom: 2rem;
  .part-title__slogan {
    font-size: 18px;
    font-size: 1.125rem;
    margin-bottom: 0.2rem;
    &.green {
      color: #1D9C56;
    }
    &.brown {
      color: #9C531D;
    }
    &.blue {
      color: #1D9C8F;
    }
    &.red {
      color: #9C1D1D;
    }
  }
  .part-title__name {
    font-size: 32px;
    font-size: 2rem;
    font-family: $title-font;
  }
}
@media screen and (min-width: 992px) {
  .part-title {
    .part-title__slogan {
      font-size: 20px;
      font-size: 1.25rem;
    }
    .part-title__name {
      font-size: 30px;
      font-size: 1.875rem;
    }
  }
}

@media screen and (min-width: 1200px) {
  .part-title {
    margin-bottom: 3rem;
    .part-title__name {
      font-size: 48px;
      font-size: 3rem;
    }
  }
}