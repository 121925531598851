#topinfo {
    background: $yellow-color;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem;
    line-height: 1.3;
    position: relative;
    z-index: 90;
    .content {
        text-align: center;
        a {
            text-decoration: underline;
        }
    }
}
