#breadcrumbs {
  padding-top: 30px;
  ul {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 1;
    li {
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      padding: 0;
      margin: 0 10px 0 0;
      &.spacer {
        color: $grey-color;
        i {
          font-size: 0.75rem;
        }
      }
      a {
        color: $black-color;
        font-size: 0.875rem;
        line-height: 1;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    li:last-child {
      display: none;
    }
  }
}

@media screen and (min-width: 1200px) {
  #breadcrumbs {
    padding-top: 0px;
  }
}