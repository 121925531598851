#product-detail {
    padding-top: 30px;
    padding-bottom: 2rem;

    h1 {
        font-family: $title-font;
        font-size: 40px;
        font-size: 2.5rem;
        font-weight: 500;
        margin-bottom: 1rem;
        line-height: 1.2;
    }

    .h1-mobile {
        font-family: $title-font;
        font-size: 32px;
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
    }

    .type {
        margin-bottom: 0.5rem;
        text-align: center;
    }

    .availability {
        color: #1D9C56;
        margin-bottom: 1rem;
        text-align: center;

        &.preorder {
            color: $red-color;
        }
    }

    .product-vertical__preorder-text {
        font-size: 0.875rem;
        margin-bottom: 5px;
        color: #777777;
        text-align: center;
    }

    .buy-button {
        padding: 12px 0;
        width: 100%;
        background: #1D9C56;
        color: #fff;
        font-size: 1.25rem;
        padding: 1rem;
    }

    .buy-button:hover {
        background: #188047;
    }

    .product-vertical__preorder {
        a {
            width: 100%;
            background: #1D9C56;
            color: #fff;
            font-size: 1.25rem;
            padding: 1rem;

            &:hover {
                background: #188047;
            }
        }
    }

    .description {
        line-height: 1.5;
        padding-top: 1.5rem;

        p {
            margin-bottom: 1.5rem;

            &.image {
                padding: 0;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &.taste {
                font-size: 30px;
                font-size: 1.875rem;
                line-height: 1;
                font-family: $title-font;
                margin-bottom: 1.5rem;
            }
        }

        .more {
            display: none;

            &.active {
                display: block;
            }
        }

        &.preorder {
            p {
                &.image {
                    img {
                        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                        filter: grayscale(100%);
                    }
                }
            }
        }
    }

    .mainimage {
        text-align: center;
        position: relative;

        .video {
            max-width: 660px;
            overflow: hidden;
            margin: 0 auto;
        }

        img {
            max-width: 100%;

            &.preorder {
                -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
                filter: grayscale(100%);
            }
        }

        .sales {
            position: absolute;
            top: 5px;
            left: 15px;
        }
    }

    .moreimages {
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1.5rem;
        margin-left: -5px;
        margin-right: -5px;
        align-items: center;
        justify-content: center;

        .moreimage {
            padding-left: 5px;
            padding-right: 5px;
        }

        a {
            display: block;
            padding: 5px 5px;
            border: 1px solid $border-color;
            overflow: hidden;
            box-sizing: border-box;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            border-radius: 2px;
            width: 70px;

            &:hover,
            &.active {
                border-color: $green-color;
            }
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .delivery-block {
        margin-top: 1.5rem;

        .delivery-block__title {
            font-size: 1.125rem;
            margin-bottom: .7rem;
        }

        .region {
            margin-bottom: .7rem;
        }

        .item {
            margin-bottom: .7rem;

            .grey_desc {
                font-size: .875rem;
                margin-top: .2rem;
            }
        }

        a {
            color: #777;
            text-decoration: underline;

            &:hover {
                color: $black-color;
            }
        }
    }

    .product-set-list {
        .part-title {
            margin-bottom: 1.5rem;
        }

        .item {
            padding: 30px 0;
            border-bottom: 1px solid $border-color;
            text-align: center;

            &:last-child {
                border-bottom: 0;
            }

            a {
                color: $black-color;

                &:hover {
                    color: $green-color;
                }
            }

            .image {
                margin-bottom: 1rem;

                img {
                    max-width: 100%;
                }
            }

            .name {
                font-family: $title-font;
                font-size: 18px;
                font-size: 1.125rem;
                font-weight: 500;
                padding-bottom: 1rem !important;
            }

            .text {
                line-height: 1.3;

                a {
                    color: $green-color;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .sticker {
        padding: 10px;
        box-sizing: border-box;
        font-size: 1.125rem;
        -webkit-border-radius: 24px;
        -moz-border-radius: 24px;
        border-radius: 24px;
        position: relative;
        font-weight: 700;
        background: #fff;
        text-align: center;
        margin: 0 auto .5rem auto;

        &.hit {
            background: $yellow-color;
            color: $black-color;
            width: 184px;
        }

        &.new {
            background: $green-color;
            color: #fff;
            width: 184px;
        }

        &.hot {
            color: #fff;
            width: 184px;
            background: $red-color;
        }

        &.liquidation {
            color: #fff;
            width: 184px;
            background: $black-red-color;
        }

        &.best {
            background: #9c1d6b;
            color: #fff;
            width: 184px;
        }

        &.exclusively {
            background: #9c591d;
            color: #fff;
            width: 184px;
        }

        &.preorder {
            background: $olive-color;
            color: #fff;
            width: 160px;
        }

        &.antiprice {
            background: $yellow-color;
            color: $black-color;
            width: 184px;
        }
    }

    .products-list-tea-sets {
        padding-top: 2rem;
    }
}

@media screen and (min-width: 576px) {
    #product-detail {
        .product-set-list {
            .item {
                text-align: left;
            }
        }

        .product-vertical__preorder-text {
            text-align: left;
        }
    }
}

@media screen and (min-width: 768px) {
    #product-detail {
        .type {
            text-align: left;
        }

        .availability {
            text-align: left;
        }

        .sticker {
            margin: 0 0 .5rem 0;
        }
    }
}

@media screen and (min-width: 1200px) {
    #product-detail {
        .sticky-top {
            top: 40px;
        }

        .availability {
            font-size: 18px;
            font-size: 1.125rem;
        }

        .moreimages {
            a {
                width: 120px;
            }
        }

        .buy-button {
            width: 60%;
        }

        .product-vertical__preorder {
            a {
                width: 70%;
            }
        }

        .description {
            font-size: 22px;
            font-size: 1.375rem;

            p {
                &:not(.image) {
                    padding-left: 120px;
                    padding-right: 120px;
                }

                &.taste {
                    font-size: 48px;
                    font-size: 3rem;
                    line-height: 1.3;
                }
            }
        }

        .reviews {
            .title {
                font-size: 48px;
                font-size: 3rem;
                margin-bottom: 3rem;
            }

            .item {
                padding-left: 150px;
                padding-right: 150px;
                margin-bottom: 2.5rem;

                .name {
                    font-size: 22px;
                    font-size: 1.375rem;
                }

                .text {
                    border-bottom: 2px solid $border-color;
                    padding-bottom: 2.5rem;
                    line-height: 1.5;
                }
            }
        }

        .product-set-list {
            padding-left: 150px;
            padding-right: 150px;

            .item {
                .name {
                    font-size: 1.75rem;
                    line-height: 1.3;
                }

                .text {
                    font-size: 22px;
                    font-size: 1.375rem;
                    line-height: 1.7;
                }
            }
        }
    }
}

@media screen and (min-width: 1500px) {
    #product-detail {
        h1 {
            font-size: 44px;
            font-size: 2.75rem;
        }

        .description {
            font-size: 22px;
            font-size: 1.375rem;

            p {
                &:not(.image) {
                    padding-left: 200px;
                    padding-right: 200px;
                    line-height: 1.7;
                }

                &.taste {
                    line-height: 1.3 !important;
                }
            }
        }

        .product-set-list {
            padding-left: 200px;
            padding-right: 200px;
            line-height: 1.7;
        }
    }
}
