#products-filter {
  .dropdown {
    position: absolute;
    top: 55px;
    left: 0px;
    z-index: 200;
    width: 100%;
    text-align: left;
    padding-top: 12px;
    .inner {
      box-shadow: 0 5px 15px 0 rgba(0,0,0,.12), 0 9px 46px 0 rgba(0,0,0,.06);
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      background: #fff;
      padding: 30px 30px;
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: calc(50% - 5px);
        bottom: 100%;
        width: 0;
        height: 0;
        border-bottom: 10px solid #fff;
        border-top: 10px solid transparent;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #products-filter {
    .dropdown {
      .inner {
        &:after {
          left: 103px;
        }
      }
    }
  }
}