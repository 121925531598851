@import "topnav";
@import "mainmenu";
@import "mainmenu-dropdown";
@import "mainmenu-mobile";
@import "header-logo";
@import "topinfo";

#app {
  padding-top: 68px;
}

@media screen and (min-width: 768px) {
  #app {
    padding-top: 78px;
  }
}

@media screen and (min-width: 992px) {
  #app {
    padding-top: 0;
  }
}

.header {
  padding-top: 1.15rem;
  padding-bottom: 1.15rem;
  z-index: 101;
  position: relative;
  background: #fff;
  border-bottom: 1px solid $border-color;
  position: fixed;
  top: 0px;
  left: 0px;
  .row {
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .header {
    border-bottom: none;
  }
}

.header__phones {
  text-align: center;
  .header__phones-title {
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }
}

.header__phone {
  display: inline-block;
  font-size: 1.375rem;
  /*background: url("../../../assets/frontend/images/header-phone.svg") no-repeat left center;*/
  /*background-size: 10px;*/
  /*padding-left: 15px;*/
  font-family: $title-font;
}

.header__recall {
  font-size: 0.875rem;
  &:hover {
    color: $green-color;
  }
}

.header-search-modal-overlay {
  display: none;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .6;
  z-index: 102;
  &.active {
    display: block;
  }
}

.header-search-modal {
  display: none;
  position: fixed;
  top: 20px;
  left: 50%;
  margin-left: -150px;
  width: 300px;
  height: 92px;
  @include default-border-radius();
  background: #fff;
  box-sizing: border-box;
  padding: 1.5rem;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.12), 0 9px 46px 0 rgba(0,0,0,.06);
  z-index: 103;
  &.active {
    display: block;
  }
  form {
    display: block;
    position: relative;
    input[type=text] {
      padding: 10px 45px 9px 13px;
      background: $border-color;
      height: 42px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      border: 1px solid $border-color;
      width: 100%;
      font-size: 1rem;
    }
    input[type=submit] {
      position: absolute;
      top: 2px;
      right: 3px;
      display: block;
      height: 37px;
      width: 39px;
      padding: 0px;
      margin: 0px;
      border: 0px;
      background: $border-color url('"../../../assets/frontend/images/header-search.svg') no-repeat center center;
      background-size: 18px;
      text-indent: -9999px;
    }
  }
}

.header-search {
  form {
    display: block;
    position: relative;
    input[type=text] {
      padding: 10px 45px 9px 13px;
      background: $border-color;
      height: 42px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      border: 1px solid $border-color;
      font-size: 0.875rem;
      width: 100%;
    }
    input[type=submit] {
      position: absolute;
      top: 2px;
      right: 3px;
      display: block;
      height: 37px;
      width: 39px;
      padding: 0px;
      margin: 0px;
      border: 0px;
      background: $border-color url('"../../../assets/frontend/images/header-search.svg') no-repeat center center;
      background-size: 18px;
      text-indent: -9999px;
    }
  }
}

.header-buttons {
  text-align: right;
}

.header-button {
  display: inline-block;
  vertical-align: top;
  position: relative;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
  &:hover svg {
    fill: $green-color;
  }
  .icon {
    width: 20px;
  }
  .text {
    display: none;
  }
  .count {
    position: absolute;
    top: -2px;
    left: 17px;
    background: $red-color;
    height: 14px;
    width: 14px;
    font-size: 8px;
    font-size: 0.5rem;
    text-align: center;
    color: #fff;
    line-height: 14px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
}

.header-button:not(:last-child) {
  margin-right: 1rem;
}

@media screen and (min-width: 576px) {
  .header-button {
    svg {
      width: 25px;
      height: 25px;
    }
    .icon {
      width: 25px;
    }
    .count {
      left: 22px;
    }
  }

  .header-button:not(:last-child) {
    margin-right: 35px;
  }
}

@media screen and (min-width: 768px) {
  .header-button {
    &.search {
      display: none;
    }
    .count {
      top: -7px;
      left: 17px;
      height: 17px;
      width: 17px;
      line-height: 17px;
      -webkit-border-radius: 17px;
      -moz-border-radius: 17px;
      border-radius: 17px;
      font-size: 11px;
      font-size: 0.6875rem;
    }
  }
}

@media screen and (min-width: 992px) {
  .header {
    position: relative;
    top: auto;
    left: auto;
  }
}

@media screen and (min-width: 1200px) {
  .header-button {
    .icon {
      display: inline-block;
      vertical-align: middle;
    }
    .text {
      display: inline-block;
      vertical-align: middle;
    }
    &:hover .text {
      color: $green-color;
    }
    .count {
      left: 14px;
    }
  }
}
