.footer {
  background: #eee;
  padding-top: 5rem;
  padding-bottom: 5rem;
  color: $black-color;
}

.footer-social {
  text-align: center;
  margin-bottom: 1.5rem;
  .footer-social__title {
    color: $black-color;
    margin-bottom: 1.5rem;
  }
  .footer-social__items {
    display: block;
    margin: 0;
    padding: 0;
  }
  .footer-social__items li {
    display: inline-block;
    vertical-align: top;
  }
  .footer-social__items li {
    margin-right: 2rem;
    &.last {
      margin-right: 0px;
    }
  }
  .footer-social__items a {
    color: $black-color;
    font-size: 1rem;
  }
}

@media screen and (min-width: 992px) {
  .footer {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .footer-social {
    margin-bottom: 0;
    .footer-social__items {
      a {
        font-size: 1.3rem;
      }
    }
  }
}

.footer-menu {
  text-align: center;
  margin-bottom: 2rem;
  .footer-menu__title {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1.2rem;
  }
  .footer-menu__items {
    display: block;
    margin: 0;
    padding: 0;
  }
  .footer-menu__items li {
    display: block;
    margin: 0;
    padding: 0;
  }
  .footer-menu__items li:not(:last-child) {
    margin-bottom: .7rem;
  }
  .footer-menu__items a {
    color: $black-color;
  }
  .footer-menu__items a:hover {
    color: $grey-color;
  }
  .footer-menu__contact:not(:last-child) {
    margin-bottom: 1.2rem;
  }
  .footer-menu__contact-name {
    font-size: 12px;
    font-size: 0.75rem;
    margin-bottom: .4rem;
  }
  .footer-menu__contact-text {
    font-size: 18px;
    font-size: 1.125rem;
    a {
      font-weight: 500;
      color: $black-color;
    }
    a:hover {
      color: $grey-color;
    }
  }
}
.footer-menu.last {
  margin-bottom: 0;
}
@media screen and (min-width: 992px) {
  .footer-menu {
    text-align: left;
    margin-bottom: 0;
    .footer-menu__items {
      font-size: 14px;
      font-size: 0.875rem;
    }
  }
}

@media screen and (min-width: 1200px) {
  .footer-menu {
    .footer-menu__items {
      font-size: 16px;
      font-size: 1rem;
    }
  }
}

.copyright {
  background: #eee;
  text-align: center;
  .container {
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .footer-copyright {
    color: $black-color;
    line-height: normal;
    margin-bottom: 1.5rem;
    .image {
      margin-bottom: 1rem;
      img {
        width: 250px;
        height: auto;
      }
    }
  }
}
.footer-copyright-mobile {
  margin-bottom: 1.5rem;
  text-align: center;
  .image {
    img {
      width: 250px;
      height: auto;
    }
  }
}
@media screen and (min-width: 992px) {
  .copyright {
    .row {
      align-items: center;
    }
    .footer-copyright {
      font-size: 14px;
      font-size: 0.875rem;
      margin-bottom: 0;
    }
  }
}
@media screen and (min-width: 1200px) {
  .copyright {
    .footer-copyright {
      .image {
        margin-bottom: .4rem;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .copyright {
    .footer-copyright {
      font-size: 16px;
      font-size: 1rem;
      .image {
        margin-bottom: 1rem;
      }
    }
  }
}

footer .end {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background: #eee;
  color: $grey-color;
  font-size: 12px;
  font-size: 0.75rem;
}

.footer-payment {
  text-align: center;
  .footer-payment__title {
    color: $black-color;
    margin-bottom: 1.5rem;
  }
  .footer-payment__items {
    display: block;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      vertical-align: top;
    }
    li:not(:last-child) {
      margin-right: 1rem;
    }
    a {
      font-size: 1.125rem;
      color: $black-color;
    }
  }
}

@media screen and (min-width: 992px) {
  .footer-payment {
    .footer-payment__items {
      a {
        font-size: 1.3rem;
      }
    }
  }
}