.buy-button {
  display: inline-block;
  @include default-border-radius();
  border: 1px solid $green-color;
  text-align: center;
  padding: .5rem 1rem;
  text-decoration: none;
  color: $green-color;
  vertical-align: top;
  visibility: visible;
  line-height: 1;
  &:hover {
    background: $green-color;
    color: #fff;
  }
  &.success {
    background: $green-color;
    color: #fff;
  }
  &.loaded {
    background: $green-color-loaded url('/images/frontend/loading-green.svg') no-repeat center center;
    border: 1px solid $green-color-loaded;
    -webkit-background-size: 30px;
    -moz-background-size: 30px;
    -o-background-size: 30px;
    background-size: 30px;
  }
}