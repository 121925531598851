#faq {
    margin-bottom: 3rem;
    .h1 {
        font-size: 32px;
        font-size: 2rem;
        font-family: $title-font;
        font-weight: normal;
        padding-bottom: 2rem;
        text-align: center;
    }
    .faq-item {
        border-bottom: 1px solid $border-color;
        button[aria-expanded='true'] {
            border-bottom: 1px solid $green-color;
        }
        button {
            position: relative;
            display: block;
            text-align: left;
            width: 100%;
            padding: 1em 0;
            color: $black-color;
            font-size: 1.15rem;
            font-weight: 400;
            border: none;
            background: none;
            outline: none;
            &:hover, &:focus {
                cursor: pointer;
                color: $green-color;
                &::after {
                    cursor: pointer;
                    color: $green-color;
                    border: 1px solid $green-color;
                }
            }
        }
        .question-title {
            padding: 1em 1.5em 1em 0;
        }
        .icon {
            display: inline-block;
            position: absolute;
            top: 18px;
            right: 0;
            width: 22px;
            height: 22px;
            border: 1px solid;
            border-radius: 22px;
            &::before {
                display: block;
                position: absolute;
                content: '';
                top: 9px;
                left: 5px;
                width: 10px;
                height: 2px;
                background: currentColor;
            }
            &::after {
                display: block;
                position: absolute;
                content: '';
                top: 5px;
                left: 9px;
                width: 2px;
                height: 10px;
                background: currentColor;
            }
        }
        button[aria-expanded='true'] {
            color: $green-color;
            .icon {
                &::after {
                    width: 0;
                }
            }
            + .answer {
                opacity: 1;
                max-height: none;
                transition: all 200ms linear;
                will-change: opacity, max-height;
            }
        }
        .answer {
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: opacity 200ms linear, max-height 200ms linear;
            will-change: opacity, max-height;
            p {
                font-size: 1rem;
                font-weight: 300;
                margin: 2em 0;
                line-height: 1.5;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    #faq {
        margin-bottom: 5rem;
        .h1 {
            font-size: 48px;
            font-size: 3rem;
            padding-bottom: 4rem;
        }
    }
}
