.green-bordered-button {
  display: inline-block;
  @include default-border-radius();
  border: 1px solid $green-color;
  text-align: center;
  padding: .5rem 1rem;
  text-decoration: none;
  color: $green-color;
  vertical-align: top;
  visibility: visible;
  background: #fff;
  &:hover {
    background: $green-color;
    color: #fff;
  }
}