.mainslider {
  height: 350px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  &.h-auto {
    height: auto;
  }
  .slide-image {
    a {
      display: block;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
      overflow: hidden;
      img {
        max-width: 100%;
      }
    }
  }
  .slide {
    text-align: center;
    background: url("../../assets/frontend/images/video-bg.png");
    height: 350px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    color: #fff;
    .video {
      top: 50%;
      width: 1938px;
      transform: translate(-50%, -50%);
      opacity: 0.9;
      left: 50%;
      z-index: 0;
      position: absolute;
    }
    .title {
      z-index: 1;
      .slogan {
        display: block;
      }
      .spacer {
        display: block;
        width: 40px;
        margin: .8rem auto .4rem auto;
        height: 1px;
        background: #fff;
      }
      .text {
        display: block;
        font-size: 32px;
        font-size: 2rem;
        font-family: "WG2", serif;
        margin-bottom: .8rem;
      }
      .button {
        text-align: center;
        a {
          display: inline-block;
          border: 1px solid #fff;
          color: #fff;
          padding: 1rem 1.5rem;
          border-radius: 2px;
          text-decoration: none;
          &:hover {
            background: #fff;
            color: #000;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .mainslider {
    height: 600px;
    margin-bottom: 5rem;
    &.h-auto {
      height: auto;
    }
    .slide {
      height: 600px;
      .title {
        .slogan {
          font-size: 20px;
          font-size: 1.25rem;
        }
        .spacer {
          margin: 1rem auto .2rem auto;
        }
        .text {
          font-size: 3.25rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .mainslider {
    .slide {
      .title {
        .slogan {
          font-size: 1.5rem;
        }
        .spacer {
          margin: 1rem auto 0 auto;
        }
        .text {
          font-size: 4.5rem;
        }
        .button {
          a {
            padding: 1rem 2.5rem;
            font-size: 1.125rem;
          }
        }
      }
    }
  }
}