#category {
  margin-bottom: 50px;
  .category-title {
    margin-top: 2rem;
    overflow: hidden;
    h1 {
      font-size: 36px;
      font-size: 2.25rem;
      font-weight: normal;
      text-align: center;
      margin-bottom: 3rem;
      font-family: $title-font;
    }
    .desc {
      text-align: center;
      line-height: 1.6;
      margin-top: -1.5rem;
      margin-bottom: 3rem;
    }
  }
  .topbanner {
    background: $border-color;
    height: 200px;
    margin-top: 2rem;
    &.pos {
      background-position: 70% center !important;
    }
    &.coffee {
      background: url("/storage/coffee-cat-525x200.jpg") no-repeat left top;
      background-size: 150%;
    }
  }
  .category-params-row {
    border-bottom: 1px dotted $form-grey-color;
    padding-bottom: 1.5rem;
    margin-bottom: 3rem;
    align-items: center;
    .pages {
      text-align: right;
      color: $grey-color;
    }
  }
  .category-count {
    color: $grey-color;
  }
  .sort {
    color: $grey-color;
    font-size: 14px;
    font-size: 0.875rem;
    text-align: right;
    ul {
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        vertical-align: top;
        margin: 0 10px 0 0;
        padding: 0;
        &.last {
          margin-right: 0px;
        }
        a {
          color: $grey-color;
          &.active {
            color: $green-color;
          }
          i {
            font-size: 10px;
            font-size: 0.625rem;
          }
        }
      }
    }
  }
  .filter-mobile-btn {
    margin-bottom: 1.5rem;
    a {
      padding-top: 1rem;
      padding-bottom: 1rem;
      width: 100%;
      display: block;
    }
  }
  .sub-categories {
    text-align: center;
    margin-top: -2rem;
    margin-bottom: 3rem;
    a {
      color: $black-color;
      display: inline-block;
      border-bottom: 1px dashed $black-color;
      text-align: left;
      margin-right: 1rem;
      padding-bottom: 3px;
      margin-bottom: 1rem;
      &:hover {
        color: $green-color;
        border-bottom: 1px solid $green-color;
      }
    }
  }
}

.more-products-btn {
  margin-top: 1rem;
  a {
    display: block;
    height: 40px;
    line-height: 40px;
    padding: 0;
    @include default-border-radius();
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media screen and (min-width: 576px) {
  #category {
    .topbanner {
      &.coffee {
        background: url("/storage/coffee-cat-670x200.jpg") no-repeat left top;
        background-size: auto;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #category {
    .filter-mobile-btn {
      margin-bottom: 0px;
      width: auto;
      display: inline-block;
      a {
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
    .topbanner {
      margin-top: 0px;
      &.coffee {
        background: url("/storage/coffee-cat-670x200.jpg") no-repeat left top;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  #category {
    .topbanner {
      &.coffee {
        background: url("/storage/coffee-cat-1090x200.jpg") no-repeat left top;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #category {
    .category-title {
      h1 {
        font-size: 48px;
        font-size: 3rem;
      }
      .desc {
        font-size: 21px;
        font-size: 1.313rem;
        line-height: 2.125rem;
      }
    }
    .category-count {
      font-size: 16px;
      font-size: 1rem;
    }
    .sort {
      font-size: 16px;
      font-size: 1rem;
    }
    .sub-categories {
      a {
        font-size: 1.313rem;
      }
    }
    .topbanner {
      &.coffee {
        background: url("/storage/coffee-cat-1090x200.jpg") no-repeat top center;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  #category {
    .category-title {
      .desc {
        font-size: 21px;
        font-size: 1.313rem;
        line-height: 2.125rem;
        padding-left: 5rem;
        padding-right: 5rem;
      }
    }
    .sub-categories {
      a {
        font-size: 1.313rem;
      }
    }
    .topbanner {
      &.coffee {
        background: url("/storage/coffee-cat-1390x200.jpg") no-repeat top center;
      }
    }
  }
}