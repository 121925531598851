.product-sale {
    background: url("../../../../../assets/frontend/images/sale-red.svg") no-repeat;
    background-size: 90px auto;
    width: 90px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-family: $sale-font;
    font-size: 1.625rem;
    font-weight: 700;
    color: #fff;

    &.green {
        background: url("../../../../../assets/frontend/images/sale-green.svg") no-repeat;
        background-size: 90px auto;
    }

    &.yellow {
        background: url("../../../../../assets/frontend/images/sale-yellow.svg") no-repeat;
        background-size: 90px auto;
        color: $black-color;
    }

    &.red {
        background: url("../../../../../assets/frontend/images/sale-red.svg") no-repeat;
        background-size: 90px auto;
    }

    &.blackred {
        background: url("../../../../../assets/frontend/images/sale-black-red.svg") no-repeat;
        background-size: 90px auto;
    }
}
