.variants-block {
  position: relative;
  .variants {
    border-radius: 8px;
    background-color: rgb(243, 243, 247);
    height: 40px;
    display: flex;
    position: relative;
    overflow: hidden;
    margin: 8px 0px;
    &.only-one {
      width: 50%;
    }
    .check {
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0px;
      width: calc(50%);
      transform: translateX(calc(0%));
      transition: transform 200ms ease-out 0s, width;
      padding: 2px;
      &::before {
        content: " ";
        width: 100%;
        height: 100%;
        display: block;
        background-color: rgb(255, 255, 255);
        border-radius: 7px;
        box-shadow: rgba(6, 5, 50, 0.19) 0px 6px 20px;
      }
    }
    .item {
      display: block;
      flex: 1 1 0%;
      position: relative;
      cursor: pointer;
      user-select: none;
      border-radius: 8px;
      color: rgb(0, 0, 0);
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 40px;
      font-weight: normal;
      text-align: center;
      height: 40px;
      transition: color 150ms ease 0s;
      i {
        color: $grey-color;
        margin-left: 5px;
        &:hover {
          color: $green-color;
        }
      }
    }
  }
  .description {
    position: absolute;
    background: #fff;
    left: 0px;
    bottom: 110%;
    border: 1px solid $border-color;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    padding: .5rem 2rem .5rem 1rem;
    width: 100%;
    line-height: 1.3;
    font-size: 0.875rem;
    z-index: 20000;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.12), 0 9px 46px 0 rgba(0,0,0,.06);
    .close {
      display: block;
      position: absolute;
      top: .5rem;
      right: .5rem;
      background: url("../../../../assets/frontend/images/close.svg");
      background-size: 10px 10px;
      width: 10px;
      height: 10px;
    }
    p {
      margin-bottom: .3rem !important;
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
}

@media screen and (min-width: 768px) {
  .variants-block {
   .variants {
     .item {
       font-size: 16px;
       font-size: 1rem;
     }
   }
  }
  #product-detail {
    .variants-block {
      .variants {
        .item {
          font-size: 16px;
          font-size: 1rem;

        }
      }
      .description {
        font-size: 0.875rem;
        line-height: 1.3;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .variants-block {
    width: 90%;
    margin: 0 auto;
  }
  #product-detail {
    .variants-block {
      width: 90%;
      margin: 0;
      .description {
        padding-top: .5rem;
        p {
          padding-left: 0px !important;
          padding-right: 0px !important;
          line-height: 1.3;
          margin-bottom: .3rem;
        }
      }
    }
  }
}