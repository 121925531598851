#brew {
  margin-bottom: 4rem;
  padding-left: 15px;
  padding-right: 15px;
  .container {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border: 1px solid $border-color;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
  .repeat {
    line-height: 1.3;
    text-align: center;
  }
  .param {
    text-align: center;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative;
    border-bottom: 1px dotted #D6D6D6;
    line-height: 1.3;
    &.first {
      padding-top: 1rem;
    }
    &.last {
      border: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    .image {
      margin-bottom: 1rem;
      font-size: 1.75rem;
      line-height: 1;
      height: 50px;
      &.image-1 {
        background: url("../../../../assets/frontend/images/brew-1.svg") no-repeat bottom center;
        background-size: 50px auto;
      }
      &.image-2 {
        background: url("../../../../assets/frontend/images/brew-2.svg") no-repeat bottom center;
        background-size: 50px auto;
      }
      &.image-3 {
        background: url("../../../../assets/frontend/images/brew-3.svg") no-repeat bottom center;
        background-size: 50px auto;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  #brew {
    .param {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
      &.first {
        padding-top: 0;
      }
      &.last {
        border: 0;
        margin-bottom: 0;
      }
    }
    .repeat {
      padding-top: 3rem;
    }
  }
}

@media screen and (min-width: 1200px) {
  #brew {
    padding-left: 0;
    padding-right: 0;
    .part-title {
      margin-bottom: 3.5rem;
      .part-title__name {
        font-size: 2.5rem;
      }
    }
    .container {
      margin: 0 auto;
      width: 750px;
    }
    .param {
      font-size: 18px;
      font-size: 1.125rem;
    }
  }
}

@media screen and (min-width: 1500px) {
  #brew {
    .container {
      width: 850px;
    }
  }
}