.modal-location {
  height: 200px !important;
  margin-top: -100px !important;
  .form-group {
    position: relative;
    .loading {
      display: block;
      position: absolute;
      top: 20px;
      right: 10px;
      left: auto;
      width: 20px;
      height: 20px;
      background: url("../../../../assets/frontend/images/loading-green.svg")  no-repeat center center;
      background-size: 20px 20px;
    }
    input[type=text] {
      padding-right: calc(1rem + 30px) !important;
    }
  }
  .dropdown {
    display: none;
    background: #fff;
    position: relative;
    top: -32px;
    border: 1px solid $border-color;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.12), 0 9px 46px 0 rgba(0,0,0,.06);
    max-height: 240px;
    overflow-y: auto;
    z-index: 1002;
    @include default-border-radius();
    &.active {
      display: block;
    }
    ul {
      display: block;
      padding: 0;
      margin: 0;
      li {
        padding: 0;
        margin: 0;
        display: block;
        border-bottom: 1px solid $border-color;
        a {
          display: block;
          padding: .5rem;
          .city {
            display: block;
            margin-bottom: 5px;
          }
          .region {
            display: block;
            color: $grey-color;
            font-size: 14px;
            font-size: 0.875rem;
          }
        }
      }
    }
  }
}
.show-modal-location {
  display: none;
  &.active {
    display: block;
  }
}