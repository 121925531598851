.price {
  font-size: 1.4rem;
}

.price__old {
  display: inline-block;
  text-decoration: line-through;
  font-size: 16px;
  font-size: 1rem;
}

.price__now {
  display: inline-block;
  &.red {
    color: $red-color;
  }
}

.price_currency {
  display: inline-block;
  margin-right: -4px;
  &.red {
    color: $red-color;
  }
}