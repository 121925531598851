#product-reviews {
  #add-product-review {
    margin-bottom: 2.5rem;
    &.nomarginbottom {
      margin-bottom: 0;
    }
    .button {
      text-align: center;
      a {
        display: inline-block;
        border: 1px solid $black-color;
        color: $black-color;
        padding: 1rem 1.5rem;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        &:hover {
          color: #fff;
          background: $black-color;
        }
      }
    }
    .form {
      .form-group {
        margin-bottom: 1rem;
        &.submit {
          margin-bottom: 0;
          text-align: center;
        }
      }
      input[type=text] {
        font-size: 1.125rem;
        padding: 20px;
      }
      textarea {
        display: block;
        padding: 20px;
        font-size: 1.125rem;
        border: 1px solid $border-color;
        width: 100%;
        min-height: 150px;
        &:hover,
        &:active {
          border: 1px solid $form-grey-color;
        }
      }
      .btn-form-send {
        width: 100%;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        border: 1px solid $green-color;
        text-align: center;
        padding: 1rem 1rem;
        text-decoration: none;
        display: block;
        color: $green-color;
        font-size: 20px;
        font-size: 1.25rem;
        &:hover {
          color: #fff;
          background: $green-color-hover;
          border: 1px solid $green-color-hover;
        }
      }
      .btn-form-close {
        text-align: center;
        margin-top: 1rem;
        display: inline-block;
        border-bottom: 1px dashed $form-grey-color;
        text-decoration: none;
        color: $form-grey-color;
        font-size: 1.125rem;
        &:hover {
          color: $black-color;
          border-bottom: 1px solid $black-color;
        }
      }
      .errors {
        margin-top: 1rem;
        color: $red-color;
        text-align: center;
      }
    }
    .success {
      font-size: 20px;
      font-size: 1.25rem;
      text-align: center;
      color: $green-color;
      line-height: 1.3;
    }
      .review-images-block {
          .add-button {
              display: block;
              text-align: center;
              padding: 30px;
              border: 1px dashed $border-color;
              .add-button-title {
                  font-size: 1.125rem;
              }
              .add-button-icon {
                  padding-top: 1rem;
                  padding-bottom: 1rem;
                  font-size: 1.25rem;
              }
              .add-button-text {
                  font-size: 0.75rem;
              }
          }
          input[type=file] {
              display: none;
          }
          .review-images-cropper {
              position: relative;
              img {
                  display: block;
                  max-width: 100%;
                  height: auto;
              }
              .review-images-cropper-buttons {
                  position: absolute;
                  top: 10px;
                  right: 10px;
                  z-index: 1000;
                  a {
                      display: inline-block;
                      background: #fff;
                      padding: 5px 10px;
                      font-size: 0.875rem;
                      font-size: 14px;
                      &.apply {
                          background: $green-color;
                          color: #fff;
                      }
                      &.cancel {
                          background: $black-color;
                          color: #fff;
                      }
                  }
              }
          }
          .review-images-list {
            .item {
                display: inline-block;
                width: 150px;
                height: auto;
                margin-right: .5rem;
                margin-bottom: 1rem;
                text-align: center;
                a {
                    display: inline-block;
                    font-size: 0.75rem;
                    color: $black-color;
                    border-bottom: 1px dashed $black-color;
                }
                img {
                    max-width: 100%;
                    height: auto;
                    -webkit-border-radius: 2px;
                    -moz-border-radius: 2px;
                    border-radius: 2px;
                }
            }
          }
      }
  }
}

@media screen and (min-width: 1200px) {
  #product-reviews {
    #add-product-review {
      margin: -1rem auto 2.5rem auto;
      &.nomarginbottom {
        margin-bottom: 0;
      }
      padding-right: 120px;
      .form {
        border: 1px solid $border-color;
        padding: 50px;
      }
    }
    &.nopackimage #add-product-review {
      padding-right: 0;
      width: 750px;
    }
  }
}

@media screen and (min-width: 1500px) {
  #product-reviews {
    #add-product-review {
      padding-right: 200px;
    }
    &.nopackimage #add-product-review {
      padding-right: 0;
      width: 850px;
    }
  }
}
