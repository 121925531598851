.input-text {
  background: #ffffff;
  border: 1px solid $border-color;
  @include default-border-radius();
  cursor: text;
  text-align: left;
  position: relative;
  height: 60px;
  width: 100%;
  &:hover,
  &.focused {
    border: 1px solid $form-grey-color;
  }
  label {
    position: absolute;
    top: 21px;
    left: 17px;
    color: $form-grey-color;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    transition-duration: .1s;
    transition-property: top, font-size;
    transition-timing-function: ease-in-out;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    will-change: top, font-size, color;
    pointer-events: none;
  }
  &.focused label {
    top: 8px;
    font-size: 12px;
  }
  input {
    position: relative;
    box-sizing: border-box;
    display: block;
    padding: 20px 18px 8px 17px;
    height: 60px;
    font-size: 18px;
    color: $black-color;
    cursor: text;
    width: 100%;
    border: 0;
    background: none;
    resize: none;
    outline: 0;
  }
}