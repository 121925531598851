.topnav {
  font-size: 0.875rem;
  color: #828282;
  z-index: 100;
  position: relative;
  background: #fff;
  .container {
    border-bottom: 1px solid #F1F1F1;
    padding-top: 0.77rem;
    padding-bottom: 0.77rem;
  }
  a {
    color: #828282;
    display: inline-block;
  }
  a:hover,
  a:focus {
    color: $black-color;
  }
}

.topnav-auth {
  text-align: right;
}

.topnav-info {
  text-align: center;
}

.topnav-info a {
  display: inline-block;
  margin-right: 1.5rem;
}

.topnav-info a:last-child {
  margin-right: -4px;
}

.topnav-location {
  position: relative;
  .topnav-location__text {
    font-size: 12px;
    font-size: 0.75rem;
    margin-right: 5px;
  }
}

.topnav-auth__enter {
  i {
    font-size: 12px;
    font-size: 0.75rem;
  }
}