.page-title {
  h1 {
    font-size: 32px;
    font-size: 2rem;
    font-family: $title-font;
    font-weight: normal;
    padding-bottom: 2rem;
    text-align: center;
    line-height: 1.3;
  }
}
@media screen and (min-width: 1200px) {
  .page-title {
    h1 {
      font-size: 48px;
      font-size: 3rem;
      padding-bottom: 4rem;
    }
  }
}