.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: .6;
  z-index: 1000;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -150px;
  margin-top: -150px;
  width: 300px;
  height: 300px;
  @include default-border-radius();
  background: #fff;
  z-index: 1001;
  box-sizing: border-box;
  padding: 1.5rem;
  box-shadow: 0 5px 15px 0 rgba(0,0,0,.12), 0 9px 46px 0 rgba(0,0,0,.06);
  .modal-header {
    margin-bottom: 1.5rem;
    .title {
      font-size: 18px;
      font-size: 1.125rem;
    }
  }
  .modal-success {
    text-align: center;
    .icon {
      text-align: center;
      color: $green-color;
      font-size: 1.875rem;
      margin-bottom: 1rem;
      i {
        font-size: 3.75rem;
      }
    }
    .text {
      line-height: 1.3;
    }
    .button {
      margin-top: 1rem;
      a {
        text-decoration: underline;
        color: $grey-color;
      }
    }
  }
  .modal-close {
    display: block;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background: url("../../../../assets/frontend/images/close.svg");
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
  }
  .form-group {
    input[type=text] {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .desc {
      font-size: 12px;
      font-size: 0.75rem;
      color: $grey-color;
    }
  }
  .rules {
    font-size: 0.75rem;
    line-height: 1.3;
    a {
      text-decoration: underline;
    }
  }
  &.modal-preorder {
    height: 330px;
    margin-top: -165px;
    .form-group {
      &:not(:last-child) {
        margin-bottom: .5rem;
      }
      .desc {
        margin-bottom: 5px;
      }
      input[type=text] {
        font-size: 20px;
        font-size: 1.25rem;
      }
    }
    input[type=submit] {
      display: block;
      width: 100%;
      box-sizing: border-box;
      height: 60px;
      line-height: 60px;
      padding: 0;
      font-size: 20px;
      font-size: 1.25rem;
    }
    .submit span {
      display: block;
      width: 100%;
      box-sizing: border-box;
      height: 60px;
      line-height: 60px;
      padding: 0;
      font-size: 20px;
      font-size: 1.25rem;
    }
  }
}