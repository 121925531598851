#toppagebanner {
  background: $yellow-color;
  text-align: center;
  z-index: 90;
  position: relative;
  .row {
    align-items: center;
    .content {
      padding: .6rem 1rem;
      .icon {
        display: inline-block;
        img {
          width: 60px;
          height: auto;
        }
      }
      .text {
        display: inline-block;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.3;
        span.promocode {
          display: inline-block;
          background: $red-color;
          color: $yellow-color;
          padding: .2rem .4rem;
        }
      }
    }
  }
}

#app.fixed #toppagebanner {
  display: none;
}