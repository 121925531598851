.mainmenu-dropdown-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  z-index: 9;
  -webkit-transition: all .1s ease-out;
  -moz-transition: all .1s ease-out;
  -ms-transition: all .1s ease-out;
  -o-transition: all .1s ease-out;
  transition: all .1s ease-out;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  transition-delay: 0.5s;
  opacity: 0;
  background-color: #000;
  visibility: hidden;
  &.active {
    display: block;
    visibility: visible;
    opacity: .6;
  }
}

.mainmenu-dropdown-wrapper {
  display: none;
  position: absolute;
  top: 188px;
  /*top: 230px;*/
  /*top: 268px;*/
  left: 0px;
  right: 0px;
  z-index: 10;
  max-height: 100%;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  &.active {
    display: block;
    opacity: 1;
    visibility: visible;
  }
    &.withFirstOrderBanner {
        top: 288px !important;
    }
  .mainmenu-dropdown {
    position: relative;
    margin: 0 auto;
    padding: 0;
    background-color: #fff;
    box-shadow: 0 5px 15px 0 rgba(0,0,0,.12), 0 9px 46px 0 rgba(0,0,0,.06);
    .flex {
      display: flex;
      justify-content: center;
      min-width: 960px;
      background: linear-gradient(90deg, #f2f2f2 50%, #fff 0);
      .menu {
        position: relative;
        min-width: 220px;
        padding: 24px 0;
        background: #f2f2f2;
        ul {
          display: block;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          li {
            display: block;
            margin: 0;
            cursor: pointer;
            box-sizing: border-box;
            &:hover {
              background: #fff;
            }
            &.active {
              background: #ffff;
            }
            a {
              display: block;
              padding: 10px 24px;
              cursor: pointer;
              -webkit-transition: color .12s ease-out;
              -moz-transition: color .12s ease-out;
              -ms-transition: color .12s ease-out;
              -o-transition: color .12s ease-out;
              transition: color .12s ease-out;
              text-decoration: none;
              outline: 0;
              touch-action: manipulation;
            }
            &:hover a,
            &.active a {
              color: $green-color;
              font-weight: 500;
            }
          }
        }
      }
      .sub-menu {
        width: 922px;
        background-color: #fff;
        .inner {
          padding: 24px 24px 24px 50px;
        }
      }
      .banner {
        position: relative;
        margin-top: 40px;
        width: 180px;
        .inner {
          padding-bottom: 24px;
          .image {
            background: #ffe400;
            height: 356px;
            width: 100%;
            img {
              width: 100%;
              height: auto;
            }
          }
          .image:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

.sub-menu-block {
  padding-bottom: 20px;
  line-height: 1.3;
  .main-link {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 5px;
    a:hover {
      color: $green-color;
    }
    &.new {
      i {
        color: $green-color;
      }
    }
    &.discount {
      i {
        color: $yellow-color;
      }
    }
    &.black {
      i {
        color: $black-color;
      }
    }
    &.box {
      i {
        color: $grey-color;
      }
    }
  }
  .link {
    line-height: 1.5;
    padding-left: 10px;
    a {
      color: $black-color;
      &:hover {
        color: $green-color;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .mainmenu-dropdown-wrapper {
    .mainmenu-dropdown {
      .flex {
        min-width: 960px;
        .menu {
          min-width: 220px;
          padding: 24px 0;
        }
        .sub-menu {
          width: 622px;
          .inner {
            padding: 33px 24px 24px 50px;
          }
        }
        .banner {
          width: 200px;
        }
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  .mainmenu-dropdown-wrapper {
    .mainmenu-dropdown {
      .flex {
        min-width: 960px;
        .menu {
          min-width: 220px;
          padding: 24px 0;
        }
        .sub-menu {
          width: 902px;
        }
      }
    }
  }
}
