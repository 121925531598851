@font-face {
    font-family: 'WG1';
    src: url('WG1-Bold.eot');
    src: url('WG1-Bold.eot?#iefix') format('embedded-opentype'),
        url('WG1-Bold.woff2') format('woff2'),
        url('WG1-Bold.woff') format('woff'),
        url('WG1-Bold.ttf') format('truetype'),
        url('WG1-Bold.svg#WG1-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WG1';
    src: url('WG1-Regular.eot');
    src: url('WG1-Regular.eot?#iefix') format('embedded-opentype'),
        url('WG1-Regular.woff2') format('woff2'),
        url('WG1-Regular.woff') format('woff'),
        url('WG1-Regular.ttf') format('truetype'),
        url('WG1-Regular.svg#WG1-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'WG1';
    src: url('WG1-BoldItalic.eot');
    src: url('WG1-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('WG1-BoldItalic.woff2') format('woff2'),
        url('WG1-BoldItalic.woff') format('woff'),
        url('WG1-BoldItalic.ttf') format('truetype'),
        url('WG1-BoldItalic.svg#WG1-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'WG1';
    src: url('WG1-Italic.eot');
    src: url('WG1-Italic.eot?#iefix') format('embedded-opentype'),
        url('WG1-Italic.woff2') format('woff2'),
        url('WG1-Italic.woff') format('woff'),
        url('WG1-Italic.ttf') format('truetype'),
        url('WG1-Italic.svg#WG1-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
