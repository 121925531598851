.advantages {
  text-align: center;
  margin-bottom: 2rem;

  .advantages__title {
    font-size: 30px;
    font-size: 1.875rem;
    margin-bottom: 2rem;
    line-height: 1.3;
    font-family: $title-font;
  }

  .advantage {
    margin-bottom: 2rem;
  }

  .advantage__icon,
  .advantage__title {
    margin-bottom: 1rem;
  }

  .advantage__title {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 700;
  }

  .advantage__text {
    margin-bottom: 1rem;
  }

  .advantage__link a {
    color: #6C7904;
    font-size: 18px;
    font-size: 1.125rem;
  }
}

@media screen and (min-width: 992px) {
  .advantage {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1200px) {
  .advantages {
    .advantages__title {
      font-size: 48px;
      font-size: 3rem;
      margin-bottom: 5rem;
    }
  }
}