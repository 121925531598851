@mixin default-border-radius() {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

@mixin func-transition($data) {
  -webkit-transition: $data;
  -moz-transition: $data;
  -ms-transition: $data;
  -o-transition: $data;
  transition: $data;
}