.articles-on-main {
  background: #F9FBFB;
  padding-top: 5rem;
  padding-bottom: 5rem;
  margin-bottom: 5rem;
  .articles-on-main__items {
    margin-bottom: 5rem;
  }
  .articles-on-main__item {
    text-align: center;
    margin-bottom: 2rem;
  }
  .articles-on-main__item.last {
    margin-bottom: 0;
  }
  .articles-on-main__item-image {
    display: block;
    margin-bottom: 1rem;
  }
  .articles-on-main__item-image img {
    width: 100%;
    height: auto;
  }
  .articles-on-main__item-title {
    font-size: 1.375rem;
    line-height: normal;
  }
  .articles-on-main__item-button {
    text-align: center;
  }
  .articles-on-main__item-button a {
    display: inline-block;
    border: 1px solid #1D9C8F;
    color: #1D9C8F;
    padding: 1rem 1.5rem;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
  }
  .articles-on-main__item-button a:hover {
    background: #1D9C8F;
    color: #ffffff;
  }
}

@media screen and (min-width: 992px) {
  .articles-on-main__item {
    margin-bottom: 0 !important;
  }
  .articles-on-main__item-title {
    font-size: 1.875rem !important;
  }
  .articles-on-main__item-image {
    margin-bottom: 1rem !important;
  }
}