#contacts {
  margin-top: 2rem;
  margin-bottom: 2rem;
  .text-block {
    font-size: 1.125rem;
    line-height: 1.3;
    .title {
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }
    a {
      color: $green-color;
    }
    .green {
      color: $green-color;
    }
  }
}

@media screen and (min-width: 768px) {
  #contacts {
    margin-bottom: 5rem;
  }
}

@media screen and (min-width: 1200px) {
  #contacts {
    .text-block {
      font-size: 1.25rem;
      line-height: 1.5;
      padding-left: 120px;
      padding-right: 120px;
      .title {
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (min-width: 1500px) {
  #contacts {
    .text-block {
      padding-left: 200px;
      padding-right: 200px;
    }
  }
}