#vacancies {
  margin-bottom: 3rem;
  .banner {
    margin-bottom: 2rem;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    overflow: hidden;
    background: url("/images/frontend/vacancies.jpg") no-repeat center center;
    height: 400px;
  }
  .page-title {
    margin-top: 2rem;
    margin-bottom: 2rem;
    h1 {
      padding-bottom: 0;
    }
  }
  .text {
    line-height: 1.5;
    p {
      margin-bottom: 1.5rem;
    }
    .q {
      text-align: right;
      .q-text {
        display: block;
        font-family: 'WG2';
        font-style: italic;
      }
      .q-person {
        display: block;
        font-family: 'WG2';
        font-style: italic;
      }
    }
    .list {
      margin-bottom: 1.5rem;
      .item {
        box-shadow: 0 5px 15px 0 rgba(0,0,0,.12), 0 9px 46px 0 rgba(0,0,0,.06);
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        padding: 1rem;
        margin-bottom: 2rem;
        &.last {
          margin-bottom: 0;
        }
        .title {
          font-weight: 700;
          margin-bottom: .5rem;
          font-size: 1.125rem;
        }
        ul {
          display: block;
          margin: 0;
          padding: 0;
          li {
            display: block;
            margin: 0 0 .2rem 0;
            padding: 0;
          }
        }
      }
    }
    a {
      text-decoration: underline;
    }
  }
}

@media screen and (min-width: 768px) {
  #vacancies {
    .text {
      .list {
        margin-bottom: 1.5rem;
        .item {
          margin-bottom: 0rem;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #vacancies {
    margin-bottom: 5rem;
    .page-title {
      h1 {
        padding-bottom: 0;
      }
    }
    .text {
      font-size: 22px;
      font-size: 1.375rem;
      p {
        padding-left: 120px;
        padding-right: 120px;
      }
      .list {
        padding-left: 120px;
        padding-right: 120px;
        margin-bottom: 1.5rem;
        .item {
          font-size: 1.125rem;
          min-height: 260px;
          .title {
            font-size: 22px;
            font-size: 1.375rem;
          }
        }
      }
    }
  }
}