#success {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
  h1 {
    font-size: 32px;
    font-size: 2rem;
    font-family: $title-font;
    margin-bottom: 2rem;
  }
  .icon {
    color: $green-color;
    margin-bottom: 2rem;
    font-size: 4rem;
    line-height: 1;
  }
  .order {
    font-size: 1.25rem;
    line-height: 1.3;
    margin-bottom: 2rem;
    strong {
      font-size: 1.875rem;
    }
  }
  .text {
    line-height: 1.3;
    a {
      text-decoration: underline;
    }
  }
}